import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AdminContext from "../../../context/admin/adminContext";
import styles from "./Login.module.scss";
import { MessageContext } from "../../../context/message/MessageContext";
import useLogin from "../../../data-access/useLogin";
import ThemedButton from "../../../components/Admin/ThemedButton/ThemedButton";

const Login = () => {
  let navigate = useNavigate();
  const { state, dispatch } = useContext(AdminContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { addError, addSuccess } = useContext(MessageContext);
  const { login, loginLoading } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();

    login(
      {
        email,
        password,
      },
      {
        onSuccess: (data) => {
          dispatch({ type: "login", data: data.data.user });
          navigate("/admin/form");
          addSuccess("You have logged in successfully");
        },
        onError: (err) => {
          addError({
            text: `There is problem in login: ${err.response.data.msg}`,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (state.loggedIn) navigate(`/admin/form`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.login}>
        <div className={styles.left}>
          <Link to="/">
            <img
              className={`${styles.logo}`}
              src="/images/ecatLogo.png"
              alt="ECAT Group"
            />
          </Link>
          <Link to="/#">
            <div className="flex gap-2">
              {" "}
              <img
                src="/images/ecatLogo.png"
                className="w-12 h-12"
                alt="Ecat Group"
              />
              <div className="flex self-center">ECAT Group</div>
            </div>
          </Link>
          <img
            className={styles.leftImage}
            src="/images/loginPage.jpg"
            alt=""
          />
        </div>
        <div className={styles.right}>
          <div className={styles.loginBox}>
            <div className={styles.inputBox}>
              <label className={styles.label}> Email address </label>
              <input
                id="email"
                type="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="you@example.com"
                required
                className={styles.input}
              />
            </div>
            <div className={styles.inputBox}>
              <label className={styles.label}> Password </label>
              <input
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Password"
                className={styles.input}
                id="password"
                required
                minLength="8"
              />
            </div>
            <div className={styles.btnBox}>
              <ThemedButton
                onClick={handleSubmit}
                theme={"PRIMARY"}
                className={styles.btn}
                loading={loginLoading}
              >
                Login
              </ThemedButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
