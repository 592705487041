// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toggle_toggle__g3QGe {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  width: 40px;
  height: 20px;
}
.Toggle_toggle__g3QGe .Toggle_checkmark__va4GK {
  background-color: #f77272;
  position: absolute;
  left: 0;
  width: 40px;
  height: 20px;
  border-radius: 20px;
  transition: 0.3s;
}
.Toggle_toggle__g3QGe .Toggle_checkmark__va4GK:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
  transition: 0.3s;
}
.Toggle_toggle__g3QGe.Toggle_purple__3oAvc .Toggle_checkmark__va4GK {
  background-color: #8193a8;
}
.Toggle_toggle__g3QGe.Toggle_purple__3oAvc input:checked ~ .Toggle_checkmark__va4GK {
  background: linear-gradient(106.52deg, #a282e8 -11.57%, #7e8ee7 50.39%, #4499e9 116.35%);
}
.Toggle_toggle__g3QGe.Toggle_purple__3oAvc input:disabled ~ .Toggle_checkmark__va4GK {
  opacity: 0.5;
  cursor: not-allowed;
}
.Toggle_toggle__g3QGe input {
  display: none;
}
.Toggle_toggle__g3QGe input:checked ~ .Toggle_checkmark__va4GK {
  background: #00ddd0;
}
.Toggle_toggle__g3QGe input:disabled ~ .Toggle_checkmark__va4GK {
  opacity: 0.5;
}
.Toggle_toggle__g3QGe input:checked ~ .Toggle_checkmark__va4GK:after {
  left: 23px;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Toggle/Toggle.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,WAAA;EACA,YAAA;AACF;AAAE;EACE,yBAAA;EACA,kBAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AADI;EACE,WAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AAGN;AACI;EACE,yBAAA;AACN;AAEM;EACE,wFAAA;AAAR;AAOM;EACE,YAAA;EACA,mBAAA;AALR;AASE;EACE,aAAA;AAPJ;AAQI;EACE,mBAAA;AANN;AAQI;EACE,YAAA;AANN;AAQI;EACE,UAAA;AANN","sourcesContent":[".toggle {\n  position: relative;\n  cursor: pointer;\n  user-select: none;\n  width: 40px;\n  height: 20px;\n  .checkmark {\n    background-color: #f77272;\n    position: absolute;\n    left: 0;\n    width: 40px;\n    height: 20px;\n    border-radius: 20px;\n    transition: 0.3s;\n    &:after {\n      content: \"\";\n      position: absolute;\n      left: 3px;\n      top: 3px;\n      width: 14px;\n      height: 14px;\n      background: #fff;\n      border-radius: 50%;\n      transition: 0.3s;\n    }\n  }\n  &.purple {\n    .checkmark {\n      background-color: #8193a8;\n    }\n    input {\n      &:checked ~ .checkmark {\n        background: linear-gradient(\n          106.52deg,\n          #a282e8 -11.57%,\n          #7e8ee7 50.39%,\n          #4499e9 116.35%\n        );\n      }\n      &:disabled ~ .checkmark {\n        opacity: 0.5;\n        cursor: not-allowed;\n      }\n    }\n  }\n  input {\n    display: none;\n    &:checked ~ .checkmark {\n      background: #00ddd0;\n    }\n    &:disabled ~ .checkmark {\n      opacity: 0.5;\n    }\n    &:checked ~ .checkmark:after {\n      left: 23px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle": `Toggle_toggle__g3QGe`,
	"checkmark": `Toggle_checkmark__va4GK`,
	"purple": `Toggle_purple__3oAvc`
};
export default ___CSS_LOADER_EXPORT___;
