import React, { useContext, useEffect, useState } from "react";
import styles from "./Member.module.scss";
import ImageUploader from "../ImageUploader/ImageUploader";
import Label from "../../../../../components/Admin/Label/Label";
import Input from "../../../../../components/Admin/Input/Input";
import Editor from "../Editor/Editor";
import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
import Toggle from "../../../../../components/Admin/Toggle/Toggle";
import useMember from "../../../../../data-access/useMember";
import { MessageContext } from "../../../../../context/message/MessageContext";
import Select from "../../../../../components/Admin/Select/Select";

const Members = ({ element }) => {
  const [input, setInput] = useState({ name: "", description: "", image: [] });
  const [newMember, setNewMember] = useState(true);
  const [selectedMember, setSelectedMember] = useState();
  const { addError, addSuccess } = useContext(MessageContext);

  const {
    member,
    memberLoading,
    refetchMember,
    createMember,
    createMemberLoading,
    updateMember,
    updateMemberLoading,
    deleteMember,
    deleteMemberLoading,
  } = useMember({ type: element });

  const handleSave = () => {
    if (
      !input?.name ||
      !input?.description ||
      !input?.image?.length ||
      !element
    ) {
      return addError({ text: "Fill all the details of the member." });
    }

    if (newMember)
      createMember(
        { ...input, type: element },
        {
          onSuccess: (data) => {
            refetchMember();
            return addSuccess("New Member added successfully.");
          },
          onError: (err) => {
            addError({
              text: `Error in adding member: ${err.response.data.msg}`,
            });
          },
        }
      );
    else {
      if (!element)
        return addError({
          text: "Select a type of member to update or create new member.",
        });
      updateMember(
        { memberId: selectedMember, body: { ...input, type: element } },
        {
          onSuccess: (data) => {
            refetchMember();
            return addSuccess("Member updated successfully.");
          },
          onError: (err) => {
            addError({
              text: `Error in updating member: ${err.response.data.msg}`,
            });
          },
        }
      );
    }
  };

  const handleDelete = () => {
    deleteMember(
      { memberId: selectedMember },
      {
        onSuccess: (data) => {
          refetchMember();
          return addSuccess("Member deleted successfully.");
        },
        onError: (err) => {
          addError({
            text: `Error in deleting member: ${err.response.data.msg}`,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (selectedMember) {
      const sMember = member?.find((pro) => pro?._id === selectedMember);
      setInput({
        name: sMember?.name,
        description: sMember?.description,
        image: sMember?.image,
      });
    } else setInput({ name: "", description: "", image: [] });
  }, [selectedMember]);

  useEffect(() => {
    if (newMember) {
      setInput({ name: "", description: "", image: [] });
    }
  }, [newMember]);

  useEffect(() => {
    setSelectedMember();
    setInput({ name: "", description: "", image: [] });
    if (element) refetchMember();
  }, [element]);

  return (
    <div className={styles.memberPara}>
      <div className={styles.toggleBox}>
        {" "}
        <div className={styles.toggleHeading}>Create new member</div>
        <Toggle
          checked={newMember}
          theme={"PURPLE"}
          onChange={() => setNewMember((prev) => !prev)}
          className={styles.toggle}
        />
        {element && !newMember && selectedMember && (
          <ThemedButton
            theme={"RED"}
            onClick={() => handleDelete()}
            width={"100px"}
            height={"30px"}
            loading={deleteMemberLoading}
            className={styles.delBtn}
          >
            Delete
          </ThemedButton>
        )}
      </div>

      {!newMember && (
        <div className={styles.member}>
          <Label>Select member</Label>
          <Select
            loading={memberLoading}
            value={selectedMember}
            setValue={setSelectedMember}
            options={member?.map((m) => ({
              label: m?.name,
              value: m?._id,
            }))}
            numberOfOptionsVisible="3"
          />
        </div>
      )}
      <div className={styles.nameBox}>
        <Label required>Name</Label>
        <Input value={input} setValue={setInput} name={"name"} />
      </div>
      <div className={styles.imageBox}>
        <Label>Upload Member Image</Label>
        <ImageUploader input={input} setInput={setInput} name={"image"} />
      </div>
      <div className={styles.descBox}>
        <Label>Description</Label>
        <Editor input={input} setInput={setInput} name={"description"} />
      </div>
      <ThemedButton
        theme={"PRIMARY"}
        onClick={() => handleSave()}
        width={"100%"}
        height={"40px"}
        className={styles.btn}
        loading={createMemberLoading || updateMemberLoading}
      >
        {newMember ? "Create Member" : "Update Member"}
      </ThemedButton>
    </div>
  );
};

export default Members;
