import React from "react";
import Carousel from "../Carousel/memberCorousel";
import useMember from "../../data-access/useMember";

const ExecutiveCommittee = () => {
  const { member, memberLoading } = useMember({ type: "executive" });
  return (
    <div>
      <Carousel
        data={member?.map((m) => ({
          name: m?.name,
          about: m?.description,
          urlImg: m?.image?.[0],
        }))}
        path={"/executivemember"}
      />
    </div>
  );
};

export default ExecutiveCommittee;
