import React from "react";

const ContactUs = () => {
  return (
    <div class="p-6 rounded-lg">
      <h2 class="text-xl font-semibold text-green-700">Contact Person:</h2>
      <p class="text-lg font-bold text-green-800">V. K. Singh</p>

      <div class="mt-4">
        <h3 class="text-xl font-semibold text-green-700">Head Office:</h3>
        <p class="text-gray-800">
          S.C.O. 29, IIIrd Floor, Sector-13,
          <br />
          Main Market, Urban Estate,
          <br />
          KARNAL-132001
        </p>
      </div>

      <div class="mt-4 flex flex-col md:flex-row gap-8">
        <div class="flex flex-col">
          <p class="text-xl font-semibold text-green-700">Mobile:</p>
          <p class="text-gray-800">
            <strong>95413-22229</strong>, <strong>90170-62224</strong>,{" "}
            <strong>93556-02224</strong>
          </p>

          <p class="mt-4 text-xl font-semibold text-green-700">E-mail:</p>
          <p class="text-green-800">
            <strong>
              <a href="mailto:info@excellentiasacademy.org">
                info@excellentiasacademy.org
              </a>
            </strong>
          </p>

          <p class="mt-4 text-xl font-semibold text-green-700">Website:</p>
          <p class="text-green-800">
            <strong>
              <a href="http://www.excellentiasacademy.org" target="_blank">
                www.excellentiasacademy.org
              </a>
            </strong>
          </p>
        </div>
        <div class="flex flex-col">
          <p class="text-xl font-semibold text-green-700">Phone:</p>
          <p class="text-gray-800">
            <strong>0184-2207540</strong>, <strong>0184-2207541</strong>,{" "}
            <strong>0184-2207542</strong>
          </p>

          <p class="mt-4 text-xl font-semibold text-green-700">Fax:</p>
          <p class="text-gray-800">
            <strong>0184-2207541</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
