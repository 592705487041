// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Success_errorBox__\\+s73k {
  position: relative;
  width: 380px;
  max-width: 380px;
  min-height: 50px;
  height: max-content;
  max-height: 200px;
  overflow: auto;
  background: linear-gradient(315deg, #40e0cf 0%, #36cdcf 100%);
  padding: 1rem;
  color: #ffffff;
  border-radius: 15px;
  display: flex;
  align-items: center;
}
@keyframes Success_animateAlertIn__Ew2r9 {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}
.Success_errorBox__\\+s73k.Success_isActive__upQW2 {
  animation: Success_animateAlertIn__Ew2r9 0.25s ease-in;
}
.Success_errorBox__\\+s73k .Success_closeIcon__TvChv {
  position: absolute;
  top: 13px;
  right: 8px;
  cursor: pointer;
}
.Success_errorBox__\\+s73k .Success_errorText__KuKu3 {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}
.Success_errorBox__\\+s73k .Success_errorText__KuKu3 i {
  margin-right: 10px;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageStack/components/Success/Success.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,6DAAA;EACA,aAAA;EACA,cAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE;IACE,4BAAA;EACJ;EAEE;IACE,0BAAA;EAAJ;AACF;AAGE;EACE,sDAAA;AADJ;AAIE;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;AAFJ;AAIE;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;AAGI;EACE,kBAAA;EACA,cAAA;AADN","sourcesContent":[".errorBox {\n  position: relative;\n  width: 380px;\n  max-width: 380px;\n  min-height: 50px;\n  height: max-content;\n  max-height: 200px;\n  overflow: auto;\n  background: linear-gradient(315deg, #40e0cf 0%, #36cdcf 100%);\n  padding: 1rem;\n  color: #ffffff;\n  border-radius: 15px;\n  display: flex;\n  align-items: center;\n\n  @keyframes animateAlertIn {\n    0% {\n      transform: translateX(300px);\n    }\n\n    100% {\n      transform: translateX(0px);\n    }\n  }\n\n  &.isActive {\n    animation: animateAlertIn 0.25s ease-in;\n  }\n\n  .closeIcon {\n    position: absolute;\n    top: 13px;\n    right: 8px;\n    cursor: pointer;\n  }\n  .errorText {\n    font-size: 1rem;\n    font-weight: 600;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-right: 20px;\n    i {\n      margin-right: 10px;\n      color: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorBox": `Success_errorBox__+s73k`,
	"isActive": `Success_isActive__upQW2`,
	"animateAlertIn": `Success_animateAlertIn__Ew2r9`,
	"closeIcon": `Success_closeIcon__TvChv`,
	"errorText": `Success_errorText__KuKu3`
};
export default ___CSS_LOADER_EXPORT___;
