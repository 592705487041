import ReadMore from "../Button/ReadMore";
import { Link } from "react-router-dom";
import "./CommonComponents.css";
const CommonComponents = ({ img, path, order, readmore, data, heading }) => {
  return (
    <div className="grid grid-rows-2  md:grid-rows-1 grid-flow-col gap-8 px-1 my-4 max-w-[80%] mx-auto ">
      <div
        className={`flex justify-center container imageContainer ${
          order ? "order-last" : ""
        }`}
      >
        <img
          className=" flex self-center object-fill imageContainerImg rounded-md max-h-96 max-w-72"
          src={img}
          alt="img"
        />
      </div>
      <div className="container overflow-y-scroll break-words whitespace-normal">
        <h2 className="text-2xl font-bold">{heading}</h2>
        <p dangerouslySetInnerHTML={{ __html: data }}></p>
        <Link to={`/${path}`}>
          {" "}
          {readmore ? <ReadMore data="Read more" /> : ""}
        </Link>
      </div>
    </div>
  );
};

export default CommonComponents;
