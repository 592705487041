// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.About_about__1dH7j {
  display: flex;
  flex-direction: column;
  height: 50%;
  gap: 20px;
}
.About_about__1dH7j .About_orgParagraphs__kKqai {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}
.About_about__1dH7j .About_orgParagraphs__kKqai .About_orgParaBox__YfpHB {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.About_about__1dH7j .About_orgParagraphs__kKqai .About_orgParaBox__YfpHB .About_paraHead__vHFN8 {
  display: flex;
  justify-content: space-between;
}
.About_about__1dH7j .About_orgParagraphs__kKqai .About_orgParaBox__YfpHB .About_paraHead__vHFN8 .About_head__3LRuR {
  font-size: 1.2rem;
  font-weight: 700;
}
.About_about__1dH7j .About_orgParagraphs__kKqai .About_orgParaBox__YfpHB .About_paraHead__vHFN8 i {
  display: flex;
  align-self: center;
  cursor: pointer;
}
.About_about__1dH7j .About_orgParagraphs__kKqai .About_btn__61xQ5 {
  display: flex;
  align-self: center;
  height: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Form/components/About/About.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,uBAAA;AACJ;AACI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACN;AAAM;EACE,aAAA;EACA,8BAAA;AAER;AADQ;EACE,iBAAA;EACA,gBAAA;AAGV;AADQ;EACE,aAAA;EACA,kBAAA;EACA,eAAA;AAGV;AAEI;EACE,aAAA;EACA,kBAAA;EACA,uBAAA;AAAN","sourcesContent":[".about {\n  display: flex;\n  flex-direction: column;\n  height: 50%;\n  gap: 20px;\n\n  .orgParagraphs {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    justify-content: center;\n\n    .orgParaBox {\n      display: flex;\n      flex-direction: column;\n      gap: 10px;\n      .paraHead {\n        display: flex;\n        justify-content: space-between;\n        .head {\n          font-size: 1.2rem;\n          font-weight: 700;\n        }\n        i {\n          display: flex;\n          align-self: center;\n          cursor: pointer;\n        }\n      }\n    }\n\n    .btn {\n      display: flex;\n      align-self: center;\n      height: 40px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": `About_about__1dH7j`,
	"orgParagraphs": `About_orgParagraphs__kKqai`,
	"orgParaBox": `About_orgParaBox__YfpHB`,
	"paraHead": `About_paraHead__vHFN8`,
	"head": `About_head__3LRuR`,
	"btn": `About_btn__61xQ5`
};
export default ___CSS_LOADER_EXPORT___;
