import React, { useState } from "react";
import { Worker, Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import useReport from "../../data-access/useReport";

const Report = ({ type }) => {
  const { report, reportLoading } = useReport({ type });
  const [view, setView] = useState(
    "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
  );

  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform = (slot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    Print: () => <></>,
    PrintMenuItem: () => <></>,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    SwitchTheme: () => <></>,
    SwitchThemeMenuItem: () => <></>,
  });

  return (
    <div className="flex p-4">
      <div className="flex h-[85vh] w-[25vw] p-4 justify-center">
        <div className="flex flex-col w-[100%] gap-4 overflow-y-scroll">
          {report?.map((rep) => (
            <div
              onClick={() => setView(rep?.url?.[0])}
              className="flex flex-col gap-4 cursor-pointer hover:bg-gray-200 w-[70%] h-[150px] self-center border border-4 border-gray-300 rounded-lg p-2"
            >
              <div className="flex h-[60%]">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={rep?.url?.[0]}
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                    }}
                    defaultScale={SpecialZoomLevel.PageFit}
                  />
                </Worker>
              </div>

              <div className="flex h-[20%] justify-center text-center font-semibold">
                {rep?.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex h-[85vh] w-[75vw] overflow-y-scroll">
        <div
          className="rpv-core__viewer"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              backgroundColor: "#eeeeee",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              display: "flex",
              padding: "0.25rem",
            }}
          >
            <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          </div>
          <div
            style={{
              flex: 1,
              overflow: "hidden",
            }}
          >
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={
                  view ??
                  "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
                plugins={[toolbarPluginInstance]}
                defaultScale={1.25}
              />
            </Worker>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
