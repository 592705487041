// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Media_mediaPara__-qYye {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Media_mediaPara__-qYye .Media_toggleBox__UvcCi {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -25px;
  gap: 10px;
}
.Media_mediaPara__-qYye .Media_toggleBox__UvcCi .Media_toggleHeading__Zqw1G {
  display: flex;
  color: #567191;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
  justify-content: center;
  align-self: center;
}
.Media_mediaPara__-qYye .Media_toggleBox__UvcCi .Media_toggle__zCbU5 {
  display: flex;
  align-self: center;
}
.Media_mediaPara__-qYye .Media_toggleBox__UvcCi .Media_delBtn__spD5y {
  z-index: 3;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Form/components/Media/Media.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;EACA,oBAAA;EACA,SAAA;AACJ;AACI;EACE,aAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;AACN;AAEI;EACE,aAAA;EACA,kBAAA;AAAN;AAEI;EACE,UAAA;AAAN","sourcesContent":[".mediaPara {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n\n  .toggleBox {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: -25px;\n    gap: 10px;\n\n    .toggleHeading {\n      display: flex;\n      color: #567191;\n      font-weight: 600;\n      font-size: 1rem;\n      line-height: 16px;\n      justify-content: center;\n      align-self: center;\n    }\n\n    .toggle {\n      display: flex;\n      align-self: center;\n    }\n    .delBtn {\n      z-index: 3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mediaPara": `Media_mediaPara__-qYye`,
	"toggleBox": `Media_toggleBox__UvcCi`,
	"toggleHeading": `Media_toggleHeading__Zqw1G`,
	"toggle": `Media_toggle__zCbU5`,
	"delBtn": `Media_delBtn__spD5y`
};
export default ___CSS_LOADER_EXPORT___;
