import React from 'react'
import { MdKeyboardDoubleArrowRight } from "react-icons/md"

const ReadMore = (props) => {
    return (
        <button className="flex items-center gap-2 bg-transparent   hover:bg-green-500 text-green-700 font-semibold hover:text-white my-2 px-2  border border-green-500 hover:border-transparent rounded">
            {props.data} <span><MdKeyboardDoubleArrowRight /></span>
        </button>
    )
}

export default ReadMore