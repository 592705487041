// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_container__HdY92 {
  z-index: 300;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-2px);
}
.Spinner_container__HdY92 .Spinner_lds-ring__ZY--C {
  display: inline-block;
  position: relative;
  width: 10px;
  height: 10px;
}
.Spinner_container__HdY92 .Spinner_lds-ring__ZY--C div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin: 2px;
  border: 2px solid transparent;
  border-radius: 50%;
  animation: Spinner_lds-ring__ZY--C 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-top-color: white;
}
.Spinner_container__HdY92 .Spinner_lds-ring__ZY--C div:nth-child(1) {
  animation-delay: -0.45s;
}
.Spinner_container__HdY92 .Spinner_lds-ring__ZY--C div:nth-child(2) {
  animation-delay: -0.3s;
}
.Spinner_container__HdY92 .Spinner_lds-ring__ZY--C div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes Spinner_lds-ring__ZY--C {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,2BAAA;AAAF;AACE;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;AACE;EACE,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,6BAAA;EACA,kBAAA;EACA,6EAAA;EACA,uBAAA;AACJ;AACE;EACE,uBAAA;AACJ;AACE;EACE,sBAAA;AACJ;AACE;EACE,uBAAA;AACJ;AACE;EACE;IACE,uBAAA;EACJ;EACE;IACE,yBAAA;EACJ;AACF","sourcesContent":[".container {\n  // position: absolute;\n  z-index: 300;\n  align-self: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transform: translateY(-2px);\n  .lds-ring {\n    display: inline-block;\n    position: relative;\n    width: 10px;\n    height: 10px;\n  }\n  .lds-ring div {\n    box-sizing: border-box;\n    display: block;\n    position: absolute;\n    width: 12px;\n    height: 12px;\n    margin: 2px;\n    border: 2px solid transparent;\n    border-radius: 50%;\n    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n    border-top-color: white;\n  }\n  .lds-ring div:nth-child(1) {\n    animation-delay: -0.45s;\n  }\n  .lds-ring div:nth-child(2) {\n    animation-delay: -0.3s;\n  }\n  .lds-ring div:nth-child(3) {\n    animation-delay: -0.15s;\n  }\n  @keyframes lds-ring {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Spinner_container__HdY92`,
	"lds-ring": `Spinner_lds-ring__ZY--C`
};
export default ___CSS_LOADER_EXPORT___;
