import { useMutation, useQuery } from "react-query";
import axios from "axios";

const useProject = ({ page, projectId }) => {
  const projectApi = async () => {
    return await axios.get(`v1/admin/project`).then((res) => {
      return res.data?.data;
    });
  };
  const {
    data: project,
    isLoading: projectLoading,
    refetch: refetchProject,
  } = useQuery("project", projectApi, {
    enabled: page === "projects",
  });

  const singleProjectApi = async () => {
    return await axios.get(`v1/admin/project/${projectId}`).then((res) => {
      return res.data?.data;
    });
  };
  const { data: singleProject, isLoading: singleProjectLoading } = useQuery(
    `project-${projectId}`,
    singleProjectApi,
    {
      enabled: Boolean(projectId),
    }
  );

  const createProjectApi = async (body) => {
    return await axios
      .post(`/v1/admin/project`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: createProject, isLoading: createProjectLoading } =
    useMutation(createProjectApi);

  const updateProjectApi = async ({ projectId, body }) => {
    return await axios
      .put(`/v1/admin/project/${projectId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: updateProject, isLoading: updateProjectLoading } =
    useMutation(updateProjectApi);

  const deleteProjectApi = async ({ projectId, body }) => {
    return await axios
      .delete(`/v1/admin/project/${projectId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: deleteProject, isLoading: deleteProjectLoading } =
    useMutation(deleteProjectApi);

  return {
    project,
    refetchProject,
    projectLoading,
    singleProject,
    singleProjectLoading,
    createProject,
    createProjectLoading,
    updateProject,
    updateProjectLoading,
    deleteProject,
    deleteProjectLoading,
  };
};
export default useProject;
