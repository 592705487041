import { AiOutlineAlignRight, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import {
  OurWorkItems,
  ReportsItem,
  ContactUsItems,
  LegalStatus,
  AboutUsItem,
} from "./headerData";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [space, setSpace] = useState(false);

  const spaceHande = () => {
    setSpace(!space);
  };

  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <div className=" w-full h-20 md:h-24 py-[0.8rem] px-[0.8rem] lg:px-[2rem] shadow-xl sticky top-0 bg-white z-10 ">
      <div className="upperHeader flex justify-between relative">
        <h3
          onClick={spaceHande}
          className="text-green-500 font-bold text-2xl tracking-tight cursor-pointer"
        >
          <Link to="/#">
            <div className="flex gap-2">
              {" "}
              <img
                src="/images/ecatLogo.png"
                className="w-12 h-12"
                alt="Ecat Group"
              />
              <div className="flex self-center">ECAT Group</div>
            </div>
          </Link>
        </h3>
        <div className="hidden md:block">
          <Button data="Donate Us" />
        </div>
        <div onClick={handleNav} className="md:hidden cursor-pointer">
          <AiOutlineAlignRight size={35} />
        </div>
      </div>
      <div className="flex pt-1 pb-2">
        <ul className="hidden md:flex  justify-between  w-full h-full mx-20">
          <Link to="/">
            <li className="navLink capitalize group transition duration-300 font-bold hover:text-green-400">
              Home
            </li>
          </Link>
          <div className="md:ml-0 ">
            <Dropdown name={"About Us"} data={AboutUsItem} bold />
          </div>
          <div className="md:ml-0 ">
            <Dropdown name={"Legal Status"} data={LegalStatus} bold />
          </div>
          <Link to="/ourwork">
            <li className="navLink capitalize group transition duration-300 font-bold hover:text-green-400">
              Our Work
            </li>
          </Link>
          <div className="md:ml-0 ">
            <Dropdown name="Reports" data={ReportsItem} bold />
          </div>
          <Link to="/mediacenter">
            <li className="navLink capitalize group transition duration-300 font-bold hover:text-green-400">
              Media center
            </li>
          </Link>
          <Link to="/contactus">
            <li className="navLink capitalize group transition duration-300 font-bold hover:text-green-400">
              Contact Us
            </li>
          </Link>
        </ul>
      </div>
      <div
        className={
          nav ? "md:hidden  fixed left-0 top-0 w-full h-screen bg-black/70" : ""
        }
      >
        <div
          className={
            nav
              ? "fixed left-0 top-0 w-[75%] md:w-[45%] sm:w-[60%] h-screen bg-[#ecf0f3] ease-in duration-500"
              : "fixed left-[-100%] top-0 w-[75%] ease-in duration-500"
          }
        >
          {/* Mobile View */}
          <div>
            <div className="flex w-full py-3 px-4 items-center justify-between">
              <h3
                onClick={spaceHande}
                className="text-green-500 font-bold text-xl tracking-tight cursor-pointer"
              >
                <Link to="/#">
                  <div className="flex gap-2">
                    {" "}
                    <img
                      src="/images/ecatLogo.png"
                      className="w-12 h-12"
                      alt="Ecat Group"
                    />
                    <div className="flex self-center">ECAT Group</div>
                  </div>
                </Link>
              </h3>

              <div onClick={handleNav} className=" cursor-pointer ">
                <AiOutlineClose size={25} />
              </div>
            </div>
            <div className="py-4  ">
              <ul className=" flex-col">
                <Link to="/">
                  <li
                    onClick={() => setNav(false)}
                    className="py-4 px-6  ml-2 max-w-16 items-start navLink capitalize group transition duration-300 hover:font-bold  hover:text-green-400"
                  >
                    Home
                  </li>
                </Link>

                <div className="md:ml-0 py-4 px-6 ">
                  <Dropdown name={"About Us"} data={AboutUsItem} />
                </div>
                <div className="md:ml-0 py-4 px-6 ">
                  <Dropdown name={"Legal Status"} data={LegalStatus} />
                </div>
                <Link to="/ourwork">
                  <li
                    onClick={() => setNav(false)}
                    className="py-4 px-6  ml-2 max-w-40 navLink capitalize group transition duration-300 hover:font-bold  hover:text-green-400"
                  >
                    Our Work
                  </li>
                </Link>
                <div className="md:ml-0 py-4 px-6 ">
                  <Dropdown name="Reports" data={ReportsItem} />
                </div>

                <Link to="/mediacenter">
                  <li
                    onClick={() => setNav(false)}
                    className="py-4 px-6 ml-2  max-w-40 navLink capitalize group transition duration-300 hover:font-bold hover:text-green-400"
                  >
                    Media Center
                  </li>
                </Link>
                <Link to="/contactus">
                  <li
                    onClick={() => setNav(false)}
                    className="py-4 px-6 ml-2  max-w-40 navLink capitalize group transition duration-300 hover:font-bold hover:text-green-400"
                  >
                    Contact Us
                  </li>
                </Link>
                <div className="ml-8 mt-4">
                  {" "}
                  <Button data="Donate Us" />
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
