import { useImmerReducer } from "use-immer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

import AdminContext from "./adminContext";

import { ROLES } from "../../constants/constants";
import MessageStack from "../../components/MessageStack/MessageStack";
import { OPEN_ROUTES, ROLE_ROUTES } from "../../rbac";
import Layout from "../../pages/User/Layout";
import Login from "../../pages/Admin/Login/Login";
import Home from "../../pages/User/Home";

const AdminState = ({ children }) => {
  const initialState = {
    loggedIn: Boolean(localStorage.getItem("ecatAdminToken")),
    user: {
      token: localStorage.getItem("ecatAdminToken"),
      role: Boolean(localStorage.getItem("ecatAdminToken"))
        ? ROLES.ADMIN
        : ROLES.USER,
    },
  };
  function ourReducer(draft, action) {
    switch (action.type) {
      case "login":
        draft.loggedIn = true;
        draft.user.token = action.data.token;
        draft.user.role = ROLES.ADMIN;
        return;
      case "logout":
        draft.loggedIn = false;
        draft.user.role = ROLES.USER;
        return;
      default:
    }
  }
  const [state, dispatch] = useImmerReducer(ourReducer, initialState);

  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem("ecatAdminToken", state.user.token);
    } else {
      localStorage.removeItem("ecatAdminToken");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.loggedIn]);

  return (
    <AdminContext.Provider value={{ state, dispatch }}>
      <MessageStack />
      <Router>
        <Routes>
          <Route path="/admin/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            {OPEN_ROUTES.map((route) => (
              <Route path={route.path} element={route.component} />
            ))}
          </Route>
          {ROLE_ROUTES?.[state?.user?.role]?.map((route) => (
            <Route path={route.path} element={route.component} />
          ))}
        </Routes>
      </Router>
    </AdminContext.Provider>
  );
};

export default AdminState;
