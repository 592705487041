import { useEffect, useRef } from "react";

//component

import styles from "./Success.module.scss";

const Success = ({ success, remove }) => {
  const timeoutId = useRef();

  useEffect(() => {
    timeoutId.current = setTimeout(() => {
      remove();
    }, 10000);
  });

  const handleClose = (e) => {
    e.preventDefault();
    clearTimeout(timeoutId.current);
    setTimeout(() => remove(), 100);
  };

  return (
    <div className={`${styles.errorBox} ${styles.isActive}`}>
      <span
        className={styles.closeIcon}
        onClick={(e) => {
          handleClose(e);
        }}
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
      <div className={styles.errorText}>
        <i class="fa-solid fa-circle-check"></i>
        {success.text}
      </div>
    </div>
  );
};

export default Success;
