import styles from "./ConfirmMessage.module.scss";

//component

const ConfirmMessage = ({ message, remove, yesFun, type }) => {
  const handleClose = (e) => {
    e.preventDefault();
    setTimeout(() => remove(), 100);
  };

  return (
    <div className={`${styles.errorBox} ${styles.isActive}`}>
      <div className={styles.errorText}>
        <i class="fa-solid fa-circle-exclamation"></i>

        {message.split("\n").map((line) => (
          <>
            {line} <br />
          </>
        ))}
      </div>
      {/* <div className={styles.partition}></div> */}
      <div className={styles.buttons}>
        <div
          className={styles.yes}
          onClick={(e) => {
            if (type !== "replyMailError") yesFun();
            handleClose(e);
          }}
        >
          {type === "unsubscribeError"
            ? "ADD"
            : type === "replyMailError"
            ? "OK"
            : "YES"}
        </div>

        {type !== "unsubscribeError" && type !== "replyMailError" && (
          <div
            className={styles.no}
            onClick={(e) => {
              handleClose(e);
            }}
          >
            NO
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmMessage;
