import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./style.scss";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
// axios.defaults.baseURL = "https://ecat-backend.onrender.com";
axios.defaults.baseURL = "http://118.139.164.38:8001";
// axios.defaults.baseURL = "http://localhost:3001/";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
