import React, { useContext, useEffect, useState } from "react";
import styles from "./About.module.scss";
import useAbout from "../../../../../data-access/useAbout";
import ImageUploader from "../ImageUploader/ImageUploader";
import Input from "../../../../../components/Admin/Input/Input";
import Editor from "../Editor/Editor";
import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
import Label from "../../../../../components/Admin/Label/Label";
import { MessageContext } from "../../../../../context/message/MessageContext";

const About = ({ element }) => {
  const [input, setInput] = useState();
  const { about, aboutLoading, createAbout, createAboutLoading } = useAbout();
  const { addError, addSuccess } = useContext(MessageContext);

  useEffect(() => {
    if (about?.length === 0) {
      setInput({
        homeImages: [],
        orgImages: [],
        orgDescription: "",
        orgParagraphs: [
          {
            name: "",
            description: "",
          },
        ],
        chairmanImage: [],
        chairmanName: "",
        chairmanDesc: "",
        vision: "",
        mission: "",
      });
    } else {
      setInput({
        homeImages: about?.[0]?.homeImages,
        orgImages: about?.[0]?.orgImages,
        orgDescription: about?.[0]?.orgDescription,
        orgParagraphs: about?.[0]?.orgParagraphs,
        chairmanName: about?.[0]?.chairmanName,
        chairmanDesc: about?.[0]?.chairmanDesc,
        chairmanImage: about?.[0]?.chairmanImage,
        vision: about?.[0]?.vision,
        mission: about?.[0]?.mission,
      });
    }
  }, [about]);

  const renderElement = () => {
    switch (element) {
      case "homeImages":
      case "orgImages":
      case "chairmanImage":
        return (
          <ImageUploader input={input} setInput={setInput} name={element} />
        );
      case "orgDescription":
      case "chairmanDesc":
      case "vision":
      case "mission":
        return <Editor input={input} setInput={setInput} name={element} />;
      case "chairmanName":
        return <Input value={input} setValue={setInput} name={element} />;

      case "orgParagraphs":
        return (
          <div className={styles.orgParagraphs}>
            {input[element]?.map((orgPara, index) => (
              <div className={styles.orgParaBox}>
                <div className={styles.paraHead}>
                  <div className={styles.head}>{`Paragraph ${index + 1}`}</div>
                  <i
                    class="fa-solid fa-trash fa-xl"
                    onClick={() =>
                      setInput((prev) => ({
                        ...prev,
                        orgParagraphs: prev.orgParagraphs.filter(
                          (_, i) => index !== i
                        ),
                      }))
                    }
                  ></i>
                </div>
                <div className={styles.nameBox}>
                  <Label>Name</Label>
                  <Input
                    value={orgPara.name}
                    setValue={(value) =>
                      setInput((prev) => ({
                        ...prev,
                        orgParagraphs: prev.orgParagraphs.map((para, i) => {
                          if (index === i) return { ...para, name: value };
                          else return para;
                        }),
                      }))
                    }
                  />
                </div>
                <div className={styles.descBox}>
                  <Label>Description</Label>
                  <Editor
                    input={orgPara.description}
                    setInput={(value) =>
                      setInput((prev) => ({
                        ...prev,
                        orgParagraphs: prev.orgParagraphs.map((para, i) => {
                          if (index === i)
                            return { ...para, description: value };
                          else return para;
                        }),
                      }))
                    }
                  />
                </div>
              </div>
            ))}
            <ThemedButton
              theme={"PRIMARY"}
              onClick={() =>
                setInput((prev) => ({
                  ...prev,
                  orgParagraphs: [
                    ...prev.orgParagraphs,
                    { name: "", description: "" },
                  ],
                }))
              }
              width={"50%"}
              className={styles.btn}
            >
              Add Paragraph
            </ThemedButton>
          </div>
        );

      default:
        break;
    }
  };

  const handleSave = () => {
    createAbout(input, {
      onSuccess: (data) => {
        addSuccess("You have successfully saved about section.");
      },
      onError: (err) => {
        addError({
          text: `There is problem saving about info: ${err.response.data.msg}`,
        });
      },
    });
  };

  return (
    <div className={styles.about}>
      {renderElement()}
      <ThemedButton
        theme={"PRIMARY"}
        onClick={() => handleSave()}
        width={"100%"}
        height={"40px"}
        className={styles.btn}
        loading={createAboutLoading}
      >
        Save
      </ThemedButton>
    </div>
  );
};

export default About;
