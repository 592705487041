import React, { useState, useEffect } from "react";
import axios from "axios";
import MemberCorousel from "../../components/FounderMember/FounderMember";
import ExecutiveCommittee from "../../components/ExecutiveCommittee/ExecutiveCommittee";
import Vision from "../../components/VisionMission/VisionMission";
import OurProjects from "../../components/OurProjects/OurProjects";
import useAbout from "../../data-access/useAbout";
import useMember from "../../data-access/useMember";

import HomeCarousel from "../../components/Carousel/HomeCarousels/HomeCarousel";
import Spinnerr from "../../components/Spinner/Spinner";
import OrganisationProfile from "../../components/OrganisationProfile/OrganisationProfile";
const Home = () => {
  // Executive Member
  const [executive, setExecutive] = useState([]);
  const [loading3, setLoading3] = useState(false);

  const { about, aboutLoading } = useAbout();

  return (
    <div>
      <div className="max-w-[90%] m-auto mt-4">
        {" "}
        <HomeCarousel about={about} />
      </div>
      <OrganisationProfile about={about} />
      <h3 className="flex justify-center font-bold text-[2rem] my-10 max-w-80 mx-auto">
        Founder Members
      </h3>
      <MemberCorousel />
      <h3 className="flex justify-center font-bold text-[2rem] my-10 max-w-80 mx-auto">
        Executive Committee
      </h3>
      <ExecutiveCommittee />
      <h3 className="flex justify-center font-bold text-[2rem] my-10 max-w-96 mx-auto">
        Our Vission & Mission
      </h3>
      <Vision about={about} />
      <h3 className="flex justify-center font-bold text-[2rem] my-10 max-w-150 mx-auto">
        New Initiatives/Projects
      </h3>
      <OurProjects />
    </div>
  );
};

export default Home;
