import React from "react";
import Gallery from "./Gallery";
import "./mediaCenter.css";

const MediaCenter = () => {
  return (
    <>
      <div className="max-w-[90%] mx-auto my-4">
        <h2 className="heading">News</h2>
        <Gallery type={"news"} />
      </div>
      <div className="max-w-[90%] mx-auto my-4">
        <h2 className="heading">Photos</h2>
        <Gallery type={"photos"} />
      </div>
      <div className="max-w-[90%] mx-auto my-4 flex flex-col justify-center">
        <h2 className="heading flex self-center">Videos</h2>
        <Gallery type={"videos"} />
      </div>
    </>
  );
};

export default MediaCenter;
