export default {
  TRANSPARENT: 'transparent',
  SECONDARY: 'secondary',
  PRIMARY: 'primary',
  GREY: 'grey',
  RED: 'red',
  ORANGE: 'orange',
  PINK: 'pink',
  WHITE: 'white',
  ACTIVE: 'active',
  GREEN: 'green',
  ICON: 'icon',
};
