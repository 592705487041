import React, { useContext, useEffect, useState } from "react";
import styles from "./Media.module.scss";
import Label from "../../../../../components/Admin/Label/Label";
import Input from "../../../../../components/Admin/Input/Input";
import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
import Toggle from "../../../../../components/Admin/Toggle/Toggle";
import useMedia from "../../../../../data-access/useMedia";
import { MessageContext } from "../../../../../context/message/MessageContext";
import Select from "../../../../../components/Admin/Select/Select";
import ImageUploader from "../ImageUploader/ImageUploader";
import VideoUploader from "../VideoUploader/VideoUploader";

const Media = ({ element }) => {
  const [input, setInput] = useState({ caption: "", url: [] });
  const [newMedia, setNewMedia] = useState(true);
  const [selectedMedia, setSelectedMedia] = useState();
  const { addError, addSuccess } = useContext(MessageContext);
  const {
    media,
    mediaLoading,
    refetchMedia,
    createMedia,
    createMediaLoading,
    updateMedia,
    updateMediaLoading,
    deleteMedia,
    deleteMediaLoading,
  } = useMedia({ type: element });

  const handleSave = () => {
    if (!element) {
      return addError({ text: "Fill all the details of the media." });
    }

    if (newMedia)
      createMedia(
        { ...input, type: element },
        {
          onSuccess: (data) => {
            refetchMedia();
            return addSuccess("New Media added successfully.");
          },
          onError: (err) => {
            addError({
              text: `Error in adding media: ${err.response.data.msg}`,
            });
          },
        }
      );
    else {
      if (!element)
        return addError({
          text: "Select a  type of media to update or create.",
        });
      updateMedia(
        { mediaId: selectedMedia, body: { ...input, type: element } },
        {
          onSuccess: (data) => {
            refetchMedia();
            return addSuccess("Media updated successfully.");
          },
          onError: (err) => {
            addError({
              text: `Error in updating media: ${err.response.data.msg}`,
            });
          },
        }
      );
    }
  };

  const handleDelete = () => {
    deleteMedia(
      { mediaId: selectedMedia },
      {
        onSuccess: (data) => {
          refetchMedia();
          return addSuccess("Media deleted successfully.");
        },
        onError: (err) => {
          addError({
            text: `Error in deleting Media: ${err.response.data.msg}`,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (selectedMedia) {
      const sMedia = media?.find((pro) => pro?._id === selectedMedia);
      setInput({
        url: sMedia?.url,
        caption: sMedia?.caption,
      });
    } else setInput({ caption: "", url: [] });
  }, [selectedMedia]);

  useEffect(() => {
    if (newMedia) {
      setInput({ caption: "", url: [] });
    }
  }, [newMedia]);

  useEffect(() => {
    setSelectedMedia();
    setInput({ caption: "", url: [] });
    if (element) refetchMedia();
  }, [element]);

  const renderElement = () => {
    switch (element) {
      case "photos":
      case "news":
        return <ImageUploader input={input} setInput={setInput} name={"url"} />;
      case "videos":
        return <VideoUploader input={input} setInput={setInput} name={"url"} />;
      default:
        break;
    }
  };
  return (
    element && (
      <div className={styles.mediaPara}>
        <div className={styles.toggleBox}>
          {" "}
          <div className={styles.toggleHeading}>Create new media</div>
          <Toggle
            checked={newMedia}
            theme={"PURPLE"}
            onChange={() => setNewMedia((prev) => !prev)}
            className={styles.toggle}
          />
          {element && !newMedia && selectedMedia && (
            <ThemedButton
              theme={"RED"}
              onClick={() => handleDelete()}
              width={"100px"}
              height={"30px"}
              loading={deleteMediaLoading}
              className={styles.delBtn}
            >
              Delete
            </ThemedButton>
          )}
        </div>

        {!newMedia && (
          <div className={styles.media}>
            <Label>Select media</Label>
            <Select
              loading={mediaLoading}
              value={selectedMedia}
              setValue={setSelectedMedia}
              options={media?.map((m, i) => ({
                label: `${element.charAt(0).toUpperCase() + element.slice(1)} ${
                  i + 1
                }`,
                value: m?._id,
              }))}
            />
          </div>
        )}
        <div className={styles.nameBox}>
          <Label required>Caption</Label>
          <Input value={input} setValue={setInput} name={"caption"} />
        </div>
        <div className={styles.elementBox}>
          <Label>Upload Media</Label>
          {renderElement()}
        </div>
        <ThemedButton
          theme={"PRIMARY"}
          onClick={() => handleSave()}
          width={"100%"}
          height={"40px"}
          className={styles.btn}
          loading={createMediaLoading || updateMediaLoading}
        >
          {newMedia ? "Create Media" : "Update Media"}
        </ThemedButton>
      </div>
    )
  );
};

export default Media;

// import React, { useContext, useEffect, useState } from "react";
// import styles from "./Media.module.scss";
// import useMedia from "../../../../../data-access/useMedia";
// import ImageUploader from "../ImageUploader/ImageUploader";
// import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
// import { MessageContext } from "../../../../../context/message/MessageContext";
// import VideoUploader from "../VideoUploader/VideoUploader";

// const Media = ({ element }) => {
//   const [input, setInput] = useState();
//   const { media, mediaLoading, createMedia, createMediaLoading } = useMedia();
//   const { addError, addSuccess } = useContext(MessageContext);
//   console.log(input, "Media inpit");
//   useEffect(() => {
//     if (media?.length === 0) {
//       setInput({
//         news: [],
//         photos: [],
//         videos: [],
//       });
//     } else {
//       setInput({
//         news: media?.[0]?.news ?? [],
//         photos: media?.[0]?.photos ?? [],
//         videos: media?.[0]?.videos ?? [],
//       });
//     }
//   }, [media]);

// const renderElement = () => {
//   switch (element) {
//     case "photos":
//     case "news":
//       return (
//         <ImageUploader input={input} setInput={setInput} name={element} />
//       );
//     case "videos":
//       return (
//         <VideoUploader input={input} setInput={setInput} name={element} />
//       );
//     default:
//       break;
//   }
// };

//   const handleSave = () => {
//     createMedia(input, {
//       onSuccess: (data) => {
//         addSuccess("You have successfully saved media section.");
//       },
//       onError: (err) => {
//         addError({
//           text: `There is problem saving media info: ${err.response.data.msg}`,
//         });
//       },
//     });
//   };

//   return (
//     <div className={styles.media}>
//       {renderElement()}
//       <ThemedButton
//         theme={"PRIMARY"}
//         onClick={() => handleSave()}
//         width={"100%"}
//         height={"40px"}
//         className={styles.btn}
//         loading={createMediaLoading}
//       >
//         Save
//       </ThemedButton>
//     </div>
//   );
// };

// export default Media;
