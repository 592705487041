import React, { useContext, useEffect, useState } from "react";
import styles from "./WorkSections.module.scss";
import Label from "../../../../../components/Admin/Label/Label";
import Input from "../../../../../components/Admin/Input/Input";
import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
import Toggle from "../../../../../components/Admin/Toggle/Toggle";
import { MessageContext } from "../../../../../context/message/MessageContext";
import useWorkSection from "../../../../../data-access/useWorkSection";
import Select from "../../../../../components/Admin/Select/Select";
import useProject from "../../../../../data-access/useProject";

const WorkSections = ({ element, setElement, workSection }) => {
  const [input, setInput] = useState({ name: "", projects: [], link: "" });
  const [newWorkSection, setNewWorkSection] = useState(!element);
  const { addError, addSuccess } = useContext(MessageContext);

  const {
    refetchWorkSection,
    createWorkSection,
    createWorkSectionLoading,
    updateWorkSection,
    updateWorkSectionLoading,
    deleteWorkSection,
    deleteWorkSectionLoading,
  } = useWorkSection({ page: "" });

  const { project, projectLoading } = useProject({ page: "projects" });

  const handleSave = () => {
    if (!input?.name || !input?.projects?.length) {
      return addError({ text: "Fill all the details of the work section." });
    }

    if (newWorkSection)
      createWorkSection(input, {
        onSuccess: (data) => {
          refetchWorkSection();
          return addSuccess("New Work Section added successfully.");
        },
        onError: (err) => {
          addError({
            text: `Error in adding work section: ${err.response.data.msg}`,
          });
        },
      });
    else {
      if (!element)
        return addError({
          text: "Select a project to update or create new project.",
        });
      updateWorkSection(
        { workSectionId: element, body: input },
        {
          onSuccess: (data) => {
            refetchWorkSection();
            return addSuccess("Work Section updated successfully.");
          },
          onError: (err) => {
            addError({
              text: `Error in updating work section: ${err.response.data.msg}`,
            });
          },
        }
      );
    }
  };

  const handleDelete = () => {
    deleteWorkSection(
      { workSectionId: element },
      {
        onSuccess: (data) => {
          refetchWorkSection();
          return addSuccess("WorkSection deleted successfully.");
        },
        onError: (err) => {
          addError({
            text: `Error in deleting Work Section: ${err.response.data.msg}`,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (element) {
      const ws = workSection?.find((ws) => ws?._id === element);
      setInput({
        name: ws?.name,
        projects: ws?.projects?.map((ws) => ws._id),
        link: ws?.link,
      });
    } else setInput({ name: "", projects: [], link: "" });

    setNewWorkSection(!element);
  }, [element]);

  useEffect(() => {
    if (newWorkSection) {
      setInput({ name: "", projects: [], link: "" });
      setElement("");
    }
  }, [newWorkSection]);

  return (
    <div className={styles.workSection}>
      <div className={styles.toggleBox}>
        {" "}
        <div className={styles.toggleHeading}>Create new work section</div>
        <Toggle
          checked={newWorkSection}
          theme={"PURPLE"}
          onChange={() => setNewWorkSection((prev) => !prev)}
          className={styles.toggle}
        />
        {element && (
          <ThemedButton
            theme={"RED"}
            onClick={() => handleDelete()}
            width={"100px"}
            height={"30px"}
            loading={deleteWorkSectionLoading}
            className={styles.delBtn}
          >
            Delete
          </ThemedButton>
        )}
      </div>

      <div className={styles.nameBox}>
        <Label>Name</Label>
        <Input value={input} setValue={setInput} name={"name"} />
      </div>
      <div className={styles.nameBox}>
        <Label>Link</Label>
        <Input value={input} setValue={setInput} name={"link"} />
      </div>
      <div className={styles.selectProject}>
        <Label>Select Project for section</Label>
        <Select
          value={input}
          setValue={setInput}
          name={"projects"}
          isMulti
          options={project?.map((p) => ({ label: p.name, value: p._id }))}
          loading={projectLoading}
          isSearchable
          height={"50px"}
        />
      </div>

      <ThemedButton
        theme={"PRIMARY"}
        onClick={() => handleSave()}
        width={"100%"}
        height={"40px"}
        className={styles.btn}
        loading={createWorkSectionLoading || updateWorkSectionLoading}
      >
        {newWorkSection ? "Create Work Section" : "Update Work Section"}
      </ThemedButton>
    </div>
  );
};

export default WorkSections;
