// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__ECcEm:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.Button_wrapper__15Rex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/ThemedButton/components/Button/Button.module.scss"],"names":[],"mappings":"AACC;EACC,YAAA;EACA,mBAAA;AAAF;;AAIA;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;AADD","sourcesContent":[".button {\n\t&:disabled {\n\t\topacity: 0.5;\n\t\tcursor: not-allowed;\n\t}\n}\n\n.wrapper {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: center;\n\talign-items: center;\n\tgap: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__ECcEm`,
	"wrapper": `Button_wrapper__15Rex`
};
export default ___CSS_LOADER_EXPORT___;
