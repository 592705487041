import React from 'react';
import './Footer.css'; // Create a CSS file for styling

const Footer = () => {
  return (
    <footer>
      <div className="footer-section">
        <h2>About Company</h2>
        <p>Your company description goes here. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione minus soluta tempore accusamus autem eos, quaerat fugiat cupiditate numquam nostrum nihil ducimus consequatur? Blanditiis asperiores dolor esse atque, laboriosam hic?</p>
      </div>

      <div className="footer-section">
        <h2>Services</h2>
        <ul>
          <li>Service 1</li>
          <li>Service 2</li>
          <li>Service 3</li>
        </ul>
      </div>

      <div className="footer-section">
        <h2>Links</h2>
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Blog</li>
        </ul>
      </div>

      <div className="footer-section">
        <h2>Contact</h2>
        <p>Email: contact@example.com</p>
        <p>Phone: +1 234 567 890</p>
      </div>
    </footer>
  );
};

export default Footer;
