import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useDidMountEffect from "../../../../../utils/useDidMountEffect";
// import { Font } from "@ckeditor/ckeditor5-font";
import "./Editor.css";
import styles from "./Editor.module.scss";
import Spinner from "../../../../../components/Admin/Spinner/Spinner";

const Editor = ({ input, setInput, name }) => {
  // console.log(input, "InputPro");
  // console.log(name, "NameProject");
  // const [editorReady, setEditorReady] = useState(false);

  // useDidMountEffect(() => {
  //   setEditorReady(false);
  //   setTimeout(() => setEditorReady(true), 1000);
  // }, []);

  // return editorReady ? (
  return (
    <CKEditor
      key={name}
      data={name ? input?.[name] : input}
      editor={ClassicEditor}
      config={{
        // plugins: [Font],
        toolbar: {
          items: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "fontfamily",
            "fontsize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "strikethrough",
            "subscript",
            "superscript",
            "|",
            "bulletedList",
            "numberedList",
          ],
          shouldNotGroupWhenFull: true,
        },
      }}
      onReady={(editor) => {
        window.editor = editor;
      }}
      onChange={(event, editor) => {
        console.log(event, editor.getData(), name, "EditEventt");
        if (name) setInput((prev) => ({ ...prev, [name]: editor.getData() }));
        else setInput(editor.getData());
      }}
    />
  );
  // ) : (
  //   <div className={styles.loaderWrapper}>
  //     <Spinner color={"#567191"} size="2rem" />{" "}
  //   </div>
  // );
};

export default Editor;
