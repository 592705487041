import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import axios from "axios";
import styles from "./VideoUploader.module.scss";
import DragNDropFile from "../../../../../components/Admin/DragNDropFile/DragNDropFile";
import { MessageContext } from "../../../../../context/message/MessageContext";
import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
import useUpload from "../../../../../data-access/useUpload";

const VideoUploader = ({ input, setInput, name }) => {
  const [video, setVideo] = useState();
  const { addError, addSuccess } = useContext(MessageContext);
  const { upload, uploadLoading } = useUpload();

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", video);

    upload(formData, {
      onSuccess: (data) => {
        setInput((prev) => ({
          ...prev,
          [name]: [...prev[name], data?.url],
        }));
        setVideo();
        return addSuccess("Vidoe Uploaded Successfully");
      },
      onError: (err) => {
        addError({
          text: `Error in uploading video: ${err.response.data.msg}`,
        });
      },
    });
  };

  return (
    <div className={styles.videoContainer}>
      <div className={styles.uploadedVideos}>
        {input?.[name]?.map((vid, index) => (
          <div className={styles.videoBox}>
            <i
              class="fa-solid fa-circle-xmark fa-lg"
              onClick={() => {
                setInput((prev) => ({
                  ...prev,
                  [name]: prev?.[name]?.filter((_, i) => i !== index),
                }));
              }}
            ></i>

            <ReactPlayer
              url={vid}
              controls
              width={"100%"}
              height={"20vh"}
              style={{ marginBottom: "20px" }}
            />
          </div>
        ))}
      </div>
      <div className={styles.videoUploader}>
        <div className={styles.videoBox}>
          <div className={styles.preview}>
            <ReactPlayer
              url={
                video
                  ? URL.createObjectURL(video)
                  : "/images/imagePlaceholder.jpg"
              }
              width={"80%"}
              height={"100%"}
              controls
            />
          </div>
          <div className={styles.input}>
            <DragNDropFile
              droppedFile={video}
              setDroppedFile={setVideo}
              maxSize={2002000000}
              className={styles.dnd}
              extnsAllowed={["jpeg", "png", "jpg", "mp4"]}
            />
          </div>
        </div>
        <div className={styles.uploadBtn}>
          <ThemedButton
            theme={"PRIMARY"}
            onClick={handleUpload}
            className={styles.btn}
            loading={uploadLoading}
          >
            Upload
          </ThemedButton>
        </div>
      </div>
    </div>
  );
};

export default VideoUploader;
