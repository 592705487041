import React from "react";
import VisionMission1 from "../../../components/VisionMission/VisionMission";
import useAbout from "../../../data-access/useAbout";
const VisionMission = () => {
  const { about, aboutLoading } = useAbout();
  return (
    <div>
      <VisionMission1 about={about} />
    </div>
  );
};

export default VisionMission;
