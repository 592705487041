import { useMutation, useQuery } from "react-query";
import axios from "axios";

const useReport = ({ type }) => {
  const reportApi = async () => {
    return await axios.get(`v1/admin/report/${type}`).then((res) => {
      return res.data?.data;
    });
  };
  const {
    data: report,
    isLoading: reportLoading,
    refetch: refetchReport,
  } = useQuery(`report-${type}`, reportApi, {
    enabled: Boolean(type),
  });

  const createReportApi = async (body) => {
    return await axios
      .post(`/v1/admin/report`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: createReport, isLoading: createReportLoading } =
    useMutation(createReportApi);

  const updateReportApi = async ({ reportId, body }) => {
    return await axios
      .put(`/v1/admin/report/${reportId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: updateReport, isLoading: updateReportLoading } =
    useMutation(updateReportApi);

  const deleteReportApi = async ({ reportId, body }) => {
    return await axios
      .delete(`/v1/admin/report/${reportId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: deleteReport, isLoading: deleteReportLoading } =
    useMutation(deleteReportApi);

  return {
    report,
    refetchReport,
    reportLoading,
    createReport,
    createReportLoading,
    updateReport,
    updateReportLoading,
    deleteReport,
    deleteReportLoading,
  };
};
export default useReport;
