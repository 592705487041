import React, { useEffect, useState } from "react";

const VisionMission = ({ about }) => {
  const [vision, setVision] = useState();
  const [mission, setMission] = useState();

  useEffect(() => {
    setVision(about?.[0]?.vision ?? " ");
    setMission(about?.[0]?.mission ?? " ");
  }, [about]);
  return (
    <div className="max-w-[80%] md:max-w-[80%]  m-auto my-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div className="block mx-2 px-[0.8rem]  py-12 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className=" mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex justify-center">
              Vision
            </h5>
            <p
              className="font-normal text-gray-700 dark:text-gray-400"
              dangerouslySetInnerHTML={{ __html: vision }}
            >
              {/* <ol type="1">
                <li>
                  1. Keeping our mission in mind, we will establish a
                  transformational leadership position in each of our projects
                  across the globe. We will become a{" "}
                  <b>
                    Comprehensive Global Leader in Cultural, Social, Economic
                    and Education, by 2030.
                  </b>
                </li>
                <li>2. Promotion of Democratic ideals,</li>
                <li>3. Strengthening of democratic institutions,</li>
                <li>
                  4. Training and Capacity Building for Elected Representatives,
                </li>
                <li>
                  5. Women Empowerment through awareness for participation and
                  decision making in Democratic Institutions
                </li>
                <li>6. Spreading Awareness among the masses.</li>
                <li>7. Spreading awareness over Children Rights.</li>
                <li>
                  8. To improve standard of living of people in our society
                  though deliver quality education among Childrens and students.
                </li>
              </ol> */}
            </p>
          </div>
        </div>
        <div>
          <div className="block  mx-2 px-[0.8rem] py-12 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white flex justify-center">
              Mission
            </h5>
            <p
              className="font-normal text-gray-700 dark:text-gray-400"
              dangerouslySetInnerHTML={{ __html: mission }}
            >
              {/* <p>
                To create <b>World's Best Institution</b> that serves the
                society for thousands of years - may be perennially. To Make
                India Global Leader in Cultural, Social, Economic and Education,
                both in Not For Profit as well as For Profit Ventures.
              </p>
              <p>
                The mission of the organization is to facilitate quality
                education and sustainable positive changes in order to eradicate
                inequality, marginalization, and poverty in the country.
              </p>
              <p>And</p>
              <p>
                A nation impowered to protect our finite natural resources,
                attuned to the pursuit of sustainable and inclusive development,
                for a clean and healthy environment that enhances the physical
                quality of life for present and future generation.
              </p> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
