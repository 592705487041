import styles from "./Warning.module.scss";

//component

const Warning = ({ warning, remove }) => {
  const handleClose = (e) => {
    e.preventDefault();
    remove();
  };

  return (
    <div className={`${styles.warningBox} ${styles.isActive}`}>
      <span
        className={styles.closeIcon}
        onClick={(e) => {
          handleClose(e);
        }}
      >
        <i class="fa-solid fa-xmark"></i>
      </span>
      <div className={styles.warningText}>
        <i class="fa-solid fa-clock"></i>
        {warning.text}
      </div>
    </div>
  );
};

export default Warning;
