// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cadence-select__value-container {
  padding: 2px 0 2px 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Select/Select.scss"],"names":[],"mappings":"AAAA;EACC,kCAAA;AACD","sourcesContent":[".cadence-select__value-container {\n\tpadding: 2px 0 2px 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
