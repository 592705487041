import { useMutation, useQuery } from "react-query";
import axios from "axios";

const useMedia = ({ type }) => {
  const mediaApi = async () => {
    return await axios.get(`v1/admin/media/${type}`).then((res) => {
      return res.data?.data;
    });
  };
  const {
    data: media,
    isLoading: mediaLoading,
    refetch: refetchMedia,
  } = useQuery(`media-${type}`, mediaApi, {
    enabled: Boolean(type),
  });

  const createMediaApi = async (body) => {
    return await axios
      .post(`/v1/admin/media`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: createMedia, isLoading: createMediaLoading } =
    useMutation(createMediaApi);

  const updateMediaApi = async ({ mediaId, body }) => {
    return await axios
      .put(`/v1/admin/media/${mediaId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: updateMedia, isLoading: updateMediaLoading } =
    useMutation(updateMediaApi);

  const deleteMediaApi = async ({ mediaId, body }) => {
    return await axios
      .delete(`/v1/admin/media/${mediaId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: deleteMedia, isLoading: deleteMediaLoading } =
    useMutation(deleteMediaApi);

  return {
    media,
    refetchMedia,
    mediaLoading,
    createMedia,
    createMediaLoading,
    updateMedia,
    updateMediaLoading,
    deleteMedia,
    deleteMediaLoading,
  };
};
export default useMedia;
