// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading {
  max-width: 0px;
  margin: auto;
  font-size: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/User/MediaCenter/mediaCenter.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".heading {\n  max-width: 0px;\n  margin: auto;\n  font-size: 24px;\n  margin-top: 12px;\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
