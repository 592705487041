import React from "react";
import { Link } from "react-router-dom";
import ReadMore from "../Button/ReadMore";

const OrganisationProfile = ({ about }) => {
  console.log(about?.orgDescription, "OrgDes");
  return (
    <div className="max-w-[80%] m-auto my-8">
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <img
            className="m-auto h-auto max-w-full rounded-lg"
            src={about?.[0]?.orgImages?.[0]}
            alt="hey"
            height={100}
            width={400}
          />
          <div className="">
            <h3 className="flex justify-center font-bold text-[2rem] my-10 max-w-80 mx-auto">
              Organisation Profile
            </h3>
            <p>
              <p
                dangerouslySetInnerHTML={{ __html: about?.[0]?.orgDescription }}
              ></p>
              <Link to={"/profileorganization"} className="text-zinc-700">
                <ReadMore data="Read more" />
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationProfile;
