// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_formBox__L1d6x {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 40px;
}
.Form_formBox__L1d6x .Form_btn__CTm9J {
  position: absolute;
  right: 20px;
  top: 20px;
}
.Form_formBox__L1d6x .Form_form__yKJiu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  width: 60%;
  max-height: 100%;
  min-height: 80vh;
  overflow: scroll;
  box-shadow: 5px 10px 50px rgba(41, 43, 88, 0.2);
  border-color: white #a282e8 white #a282e8;
  border-width: 5px;
  background-color: white;
}
.Form_formBox__L1d6x .Form_form__yKJiu::-webkit-scrollbar {
  display: none;
}
.Form_formBox__L1d6x .Form_form__yKJiu .Form_heading__J3to3 {
  display: flex;
  align-self: center;
  font-weight: 700;
  color: black;
  font-size: 1.5rem;
  height: 20%;
}
.Form_formBox__L1d6x .Form_form__yKJiu .Form_inputs__YUU86 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 40%;
}
.Form_formBox__L1d6x .Form_form__yKJiu .Form_formInside__MFeqz {
  display: flex;
  flex-direction: column;
  height: 50%;
  overflow: scroll;
}
.Form_formBox__L1d6x .Form_form__yKJiu .Form_formInside__MFeqz::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Form/Form.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAEJ;AACE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EAEA,+CAAA;EACA,yCAAA;EACA,iBAAA;EACA,uBAAA;AAAJ;AAEI;EACE,aAAA;AAAN;AAGI;EACE,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;AADN;AAGI;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,WAAA;AADN;AAGI;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;AADN;AAGM;EACE,aAAA;AADR","sourcesContent":[".formBox {\n  display: flex;\n  justify-content: center;\n  width: 100vw;\n  height: 100vh;\n  padding: 40px;\n  .btn {\n    position: absolute;\n    right: 20px;\n    top: 20px;\n  }\n\n  .form {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    align-self: center;\n    justify-content: center;\n    padding: 20px;\n    border-radius: 10px;\n    width: 60%;\n    max-height: 100%;\n    min-height: 80vh;\n    overflow: scroll;\n\n    box-shadow: 5px 10px 50px rgba(41, 43, 88, 0.2);\n    border-color: white #a282e8 white #a282e8;\n    border-width: 5px;\n    background-color: white;\n\n    &::-webkit-scrollbar {\n      display: none;\n    }\n\n    .heading {\n      display: flex;\n      align-self: center;\n      font-weight: 700;\n      color: black;\n      font-size: 1.5rem;\n      height: 20%;\n    }\n    .inputs {\n      display: flex;\n      flex-direction: column;\n      gap: 10px;\n      height: 40%;\n    }\n    .formInside {\n      display: flex;\n      flex-direction: column;\n      height: 50%;\n      overflow: scroll;\n\n      &::-webkit-scrollbar {\n        display: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formBox": `Form_formBox__L1d6x`,
	"btn": `Form_btn__CTm9J`,
	"form": `Form_form__yKJiu`,
	"heading": `Form_heading__J3to3`,
	"inputs": `Form_inputs__YUU86`,
	"formInside": `Form_formInside__MFeqz`
};
export default ___CSS_LOADER_EXPORT___;
