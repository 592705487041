import { useContext, useEffect, useRef } from "react";
import { MessageContext } from "../../../context/message/MessageContext";

import styles from "./DragNDropFile.module.scss";

const DragNDropFile = ({
  droppedFile,
  setDroppedFile,
  showFileDetails,
  extnsAllowed,
  placeholder,
  className,
  maxSize = 2000000,
  disabled,
}) => {
  let inputRef = useRef(null);
  let imageRef = useRef(null);

  const { addError } = useContext(MessageContext);

  const fileChangedHandler = (file, cb) => {
    if (disabled) return;
    if (file) {
      let ext;

      ext = file.name.split(".").pop() ?? file.type.split("/")[1];

      if (extnsAllowed.length && !extnsAllowed.includes(ext)) {
        let errorMessage = "File should be of type ";
        extnsAllowed.forEach((extn, i) => {
          errorMessage += extn;
          if (i < extnsAllowed.length - 1) errorMessage += "/";
        });
        return addError({ text: errorMessage });
      }
      if (file.size > maxSize)
        return addError({
          text: `File size should be less than ${formatBytes(maxSize)}`,
        });
      setDroppedFile(file);
    }
    if (cb && typeof cb === "function") {
      cb();
    }
  };

  const shortenFileName = (fileName) => {
    let splits = fileName.split(".");
    let name = splits[0];
    let extn = splits[splits.length - 1];
    let maxNameLen = 18 - extn.length;
    if (name.length > maxNameLen) {
      return `${name.substring(0, maxNameLen - 5)}...${name.substring(
        name.length - 2
      )}.${extn}`;
    } else {
      return `${name}.${extn}`;
    }
  };

  function formatBytes(bytes) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = 1;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const getFileSizeFromBytes = (sizeInBytes) => {
    let size = Math.floor(sizeInBytes / (1000 * 1000 * 10)) / 100;
    if (size >= 0.9) {
      return `${size} GB`;
    }
    size = Math.floor(sizeInBytes / (1000 * 10)) / 100;
    if (size >= 1) {
      return `${size} MB`;
    }
    size = Math.floor(sizeInBytes / 10) / 100;
    if (size >= 1) {
      return `${size} KB`;
    }
    return `${sizeInBytes} bytes`;
  };

  useEffect(() => {
    if (droppedFile && showFileDetails) {
      let reader = new FileReader();

      let imgtag = imageRef.current;
      imgtag.title = droppedFile?.name;

      reader.onload = function (event) {
        imgtag.src = event.target.result;
      };

      reader.readAsDataURL(droppedFile);
    }
  }, [droppedFile]);

  return (
    <div className={`${styles.wrapper} ${className ?? ""}`}>
      <div
        className={styles.dragNdrop}
        onDragOver={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          fileChangedHandler(e.dataTransfer.files[0]);
        }}
      >
        <i class="fa-solid fa-arrow-up-from-bracket"></i>
        <p>{placeholder ?? "Drag and drop file here"}</p>
        <p>or</p>
        <button
          className={styles.browseFilesBtn}
          onClick={() => {
            inputRef.click();
          }}
          disabled={disabled}
        >
          <input
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              fileChangedHandler(e.target.files[0], () => {
                e.target.value = null;
              });
            }}
            ref={(fileInput) => {
              inputRef = fileInput;
            }}
          />
          {"Browse Files"}
        </button>
      </div>
      {showFileDetails && droppedFile?.name && (
        <div className={styles.fileCard}>
          <img ref={imageRef} className={styles.image} alt="" />
          <div className={styles.info}>
            <p className={styles.fileName}>
              {shortenFileName(droppedFile.name)}
            </p>
            <p className={styles.fileSize}>
              Size: {getFileSizeFromBytes(droppedFile.size)}
            </p>
          </div>
          <i class="fa-solid fa-xmark" onClick={() => setDroppedFile(null)}></i>
        </div>
      )}
    </div>
  );
};

DragNDropFile.defaultProps = {
  showFileDetails: true,
  extnsAllowed: [],
};

export default DragNDropFile;
