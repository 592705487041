import { useEffect, useRef, useState, useContext } from "react";
import styles from "./Error.module.scss";
// import { Errors as ERROR_TRANSLATION } from "@languages";

//component
// import { Close, Caution2, CopyBlank } from "@icons";
// import { Colors } from "@utils";
// import { MessageContext } from "../../../../context/message/MessageContext";

const Error = ({ error, remove }) => {
  const timeoutId = useRef();
  // const [open, setOpen] = useState(false);
  // const { addSuccess } = useContext(MessageContext);

  useEffect(() => {
    timeoutId.current = setTimeout(() => {
      remove();
    }, 10000);
  });

  const handleClose = (e) => {
    e.preventDefault();
    clearTimeout(timeoutId.current);
    setTimeout(() => remove(), 100);
  };

  return (
    <div
      className={`${styles.errorBox} ${styles.isActive} ${
        error.onClick && styles.isOnClick
      }`}
      onClick={
        error.onClick
          ? (e) => {
              error.onClick();
              handleClose(e);
            }
          : null
      }
    >
      <div className={styles.iconcontainer}>
        <span
          className={styles.closeIcon}
          onClick={(e) => {
            e.stopPropagation();
            handleClose(e);
          }}
        >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>

      <div className={styles.errorText}>
        <i class="fa-solid fa-circle-exclamation"></i>
        {error.text}
      </div>

      {/* {error.description &&
        error.description !== error.text &&
        !open &&
        error.description?.length > 100 && (
          <div
            className={styles.showText}
            onClick={(e) => setOpen((prev) => !prev)}
          >
            {ERROR_TRANSLATION.SHOW_MORE["ENGLISH"]}
          </div>
        )}

      {error.description && open && (
        <div
          className={styles.showText}
          onClick={(e) => setOpen((prev) => !prev)}
        >
          {ERROR_TRANSLATION.SHOW_LESS["ENGLISH"]}
        </div>
      )} */}
    </div>
  );
};

export default Error;
