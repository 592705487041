import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Worker, Viewer, ScrollMode } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import styles from "./PdfUploader.module.scss";
import DragNDropFile from "../../../../../components/Admin/DragNDropFile/DragNDropFile";
import { MessageContext } from "../../../../../context/message/MessageContext";
import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
import useUpload from "../../../../../data-access/useUpload";

const PdfUploader = ({ input, setInput, name }) => {
  const [pdf, setPdf] = useState();
  const { addError, addSuccess } = useContext(MessageContext);
  const { upload, uploadLoading } = useUpload();

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", pdf);

    upload(formData, {
      onSuccess: (data) => {
        setInput((prev) => ({
          ...prev,
          [name]: [...prev[name], data?.url],
        }));
        setPdf();
        return addSuccess("Pdf Uploaded Successfully");
      },
      onError: (err) => {
        addError({
          text: `Error in uploading pdf: ${err.response.data.msg}`,
        });
      },
    });
  };

  return (
    <div className={styles.pdfContainer}>
      <div className={styles.uploadedPdfs}>
        {input?.[name]?.map((pdf, index) => (
          <div className={styles.pdfBox}>
            <i
              class="fa-solid fa-circle-xmark fa-lg"
              onClick={() => {
                setInput((prev) => ({
                  ...prev,
                  [name]: prev?.[name]?.filter((_, i) => i !== index),
                }));
              }}
            ></i>
            <div className={styles.pdfViewContainer}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={pdf}
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    width: "100%", // Set width of the viewer
                    height: "750px", // Set height of the viewer
                  }}
                />
              </Worker>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.pdfUploader}>
        <div className={styles.pdfBox}>
          <div className={styles.preview}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer
                fileUrl={
                  pdf
                    ? URL.createObjectURL(pdf)
                    : "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf"
                }
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "750px",
                }}
              />
            </Worker>
          </div>
          <div className={styles.input}>
            <DragNDropFile
              droppedFile={pdf}
              setDroppedFile={setPdf}
              className={styles.dnd}
              extnsAllowed={["pdf"]}
            />
          </div>
        </div>
        <div className={styles.uploadBtn}>
          <ThemedButton
            theme={"PRIMARY"}
            onClick={handleUpload}
            className={styles.btn}
            loading={uploadLoading}
          >
            Upload
          </ThemedButton>
        </div>
      </div>
    </div>
  );
};

export default PdfUploader;
