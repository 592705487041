import { QueryClient, QueryClientProvider } from "react-query";
import AdminState from "./context/admin/AdminState";

import MessageProvider from "./context/message/MessageContext";

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <MessageProvider>
        <AdminState />
      </MessageProvider>
    </QueryClientProvider>
  );
};

export default App;
