import React, { useEffect, useState } from "react";
import CommonComponents from "../../../components/common/CommonComponents";
import useAbout from "../../../data-access/useAbout";

const ProfileOrganization = () => {
  const { about, aboutLoading } = useAbout();
  const [orgPara, setOrgPara] = useState([]);
  const [orgImages, setOrgImages] = useState([]);

  useEffect(() => {
    setOrgPara(about?.[0]?.orgParagraphs);
    setOrgImages(about?.[0]?.orgImages);
  }, [about]);

  return (
    <div className="flex flex-col gap-4">
      {orgPara?.map((orgPara, index) => (
        <CommonComponents
          data={orgPara?.description}
          order={index % 2 !== 0}
          img={orgImages?.[index]}
          heading={orgPara?.name}
        />
      ))}
    </div>
  );
};

export default ProfileOrganization;
