// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #7ad389bd;
  color: rgb(0, 0, 0);
  padding: 20px 30px;
  margin-top: 2rem;
}
.footer-section h2 {
  font-size: larger;
  font-weight: bold;
}
.footer-section {
  flex: 1 0 250px;
  margin: 10px;
}

@media screen and (max-width: 600px) {
  footer {
    flex-direction: column;
    align-items: start;
  }

  .footer-section {
    flex: 1 0 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":["footer {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n  background-color: #7ad389bd;\n  color: rgb(0, 0, 0);\n  padding: 20px 30px;\n  margin-top: 2rem;\n}\n.footer-section h2 {\n  font-size: larger;\n  font-weight: bold;\n}\n.footer-section {\n  flex: 1 0 250px;\n  margin: 10px;\n}\n\n@media screen and (max-width: 600px) {\n  footer {\n    flex-direction: column;\n    align-items: start;\n  }\n\n  .footer-section {\n    flex: 1 0 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
