import React, { useEffect, useState } from "react";
import ImageSlider from "./HomeCarouselData";

const HomeCarousel = ({ about }) => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setSlides(about?.[0]?.homeImages);
  }, [about]);

  const containerStyles = {
    width: "100%",
    height: "440px",
    margin: "0",
  };
  return (
    <div>
      <div style={containerStyles}>
        <ImageSlider slides={slides} />
      </div>
    </div>
  );
};

export default HomeCarousel;
