import React, { useContext, useEffect, useState } from "react";
import styles from "./Report.module.scss";
import PdfUploader from "../PdfUploader/PdfUploader";
import Label from "../../../../../components/Admin/Label/Label";
import Input from "../../../../../components/Admin/Input/Input";
import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
import Toggle from "../../../../../components/Admin/Toggle/Toggle";
import useReport from "../../../../../data-access/useReport";
import { MessageContext } from "../../../../../context/message/MessageContext";
import Select from "../../../../../components/Admin/Select/Select";

const Reports = ({ element }) => {
  const [input, setInput] = useState({ name: "", url: [] });
  const [newReport, setNewReport] = useState(true);
  const [selectedReport, setSelectedReport] = useState();
  const { addError, addSuccess } = useContext(MessageContext);
  const {
    report,
    reportLoading,
    refetchReport,
    createReport,
    createReportLoading,
    updateReport,
    updateReportLoading,
    deleteReport,
    deleteReportLoading,
  } = useReport({ type: element });

  const handleSave = () => {
    if (!input?.name || !input?.url?.length || !element) {
      return addError({ text: "Fill all the details of the report." });
    }

    if (newReport)
      createReport(
        { ...input, type: element },
        {
          onSuccess: (data) => {
            refetchReport();
            return addSuccess("New Report added successfully.");
          },
          onError: (err) => {
            addError({
              text: `Error in adding report: ${err.response.data.msg}`,
            });
          },
        }
      );
    else {
      if (!element)
        return addError({
          text: "Select a  type of report to update or create.",
        });
      updateReport(
        { reportId: selectedReport, body: { ...input, type: element } },
        {
          onSuccess: (data) => {
            refetchReport();
            return addSuccess("Report updated successfully.");
          },
          onError: (err) => {
            addError({
              text: `Error in updating report: ${err.response.data.msg}`,
            });
          },
        }
      );
    }
  };

  const handleDelete = () => {
    deleteReport(
      { reportId: selectedReport },
      {
        onSuccess: (data) => {
          refetchReport();
          return addSuccess("Report deleted successfully.");
        },
        onError: (err) => {
          addError({
            text: `Error in deleting Report: ${err.response.data.msg}`,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (selectedReport) {
      const sReport = report?.find((pro) => pro?._id === selectedReport);
      setInput({
        name: sReport?.name,
        url: sReport?.url,
      });
    } else setInput({ name: "", url: [] });
  }, [selectedReport]);

  useEffect(() => {
    if (newReport) {
      setInput({ name: "", url: [] });
    }
  }, [newReport]);

  useEffect(() => {
    setSelectedReport();
    setInput({ name: "", url: [] });
    if (element) refetchReport();
  }, [element]);

  return (
    <div className={styles.reportPara}>
      <div className={styles.toggleBox}>
        {" "}
        <div className={styles.toggleHeading}>Create new report</div>
        <Toggle
          checked={newReport}
          theme={"PURPLE"}
          onChange={() => setNewReport((prev) => !prev)}
          className={styles.toggle}
        />
        {element && !newReport && selectedReport && (
          <ThemedButton
            theme={"RED"}
            onClick={() => handleDelete()}
            width={"100px"}
            height={"30px"}
            loading={deleteReportLoading}
            className={styles.delBtn}
          >
            Delete
          </ThemedButton>
        )}
      </div>

      {!newReport && (
        <div className={styles.report}>
          <Label>Select report</Label>
          <Select
            loading={reportLoading}
            value={selectedReport}
            setValue={setSelectedReport}
            options={report?.map((m) => ({
              label: m?.name,
              value: m?._id,
            }))}
          />
        </div>
      )}
      <div className={styles.nameBox}>
        <Label required>Name</Label>
        <Input value={input} setValue={setInput} name={"name"} />
      </div>
      <div className={styles.pdfBox}>
        <Label>Upload Report</Label>
        <PdfUploader input={input} setInput={setInput} name={"url"} />
      </div>
      <ThemedButton
        theme={"PRIMARY"}
        onClick={() => handleSave()}
        width={"100%"}
        height={"40px"}
        className={styles.btn}
        loading={createReportLoading || updateReportLoading}
      >
        {newReport ? "Create Report" : "Update Report"}
      </ThemedButton>
    </div>
  );
};

export default Reports;
