import React, { useEffect } from "react";
import MemberCorousel from "../Carousel/memberCorousel";
import useMember from "../../data-access/useMember";
const FounderMember = () => {
  const { member, memberLoading } = useMember({ type: "trustee" });
  return (
    <div>
      {" "}
      <MemberCorousel
        data={member?.map((m) => ({
          name: m?.name,
          about: m?.description,
          urlImg: m?.image?.[0],
        }))}
        path={"/trusteemember"}
      />
    </div>
  );
};
export default FounderMember;
