import styles from "../Input.module.scss";
import THEMES from "../themes";

const InputMultiple = ({
  type,
  value,
  setValue,
  onClick,
  name,
  top = false,
  left = false,
  width = "100%",
  height = "40px",
  className,
  isDecimalAllowed = false,
  theme = "WHITE",
  maxValue = null,
  minValue = 0,
  disabled,
  showArrows = false,
  ...rest
}) => {
  const onChange = (e) => {
    let value = e.target.value;
    setValue((prevState) => {
      return {
        ...prevState,
        [e.target.name]: value,
      };
    });
  };
  switch (type) {
    case "textarea":
      return (
        <textarea
          value={value[name] || ""}
          onChange={onChange}
          style={{ width, height }}
          name={name}
          disabled={disabled}
          className={`${styles.input} ${styles[THEMES[theme]]} ${
            className ?? ""
          }`}
          {...rest}
        />
      );

    default:
      return (
        <input
          value={value[name] || ""}
          onChange={onChange}
          style={{ width, height }}
          name={name}
          type={type}
          disabled={disabled}
          className={`${styles.input} ${styles[THEMES[theme]]} ${
            className ?? ""
          }`}
          {...rest}
        />
      );
  }
};

export default InputMultiple;
