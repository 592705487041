// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Warning_warningBox__NZKlP {
  position: relative;
  width: 380px;
  max-width: 380px;
  min-height: 50px;
  height: max-content;
  max-height: 200px;
  overflow: auto;
  background: linear-gradient(135deg, rgb(255, 207, 79) 0%, rgb(255, 155, 74) 100%);
  padding: 15px 35px 15px 12px;
  color: #ffffff;
  border-radius: 15px;
  display: flex;
  align-items: flex-start;
}
@keyframes Warning_animateAlertIn__SyRXN {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}
.Warning_warningBox__NZKlP.Warning_isActive__RPg5H {
  animation: Warning_animateAlertIn__SyRXN 0.25s ease-in;
}
.Warning_warningBox__NZKlP .Warning_closeIcon__KW8ii {
  position: absolute;
  top: 13px;
  right: 8px;
  cursor: pointer;
}
.Warning_warningBox__NZKlP .Warning_warningText__dGFeW {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-right: 20px;
}
.Warning_warningBox__NZKlP .Warning_warningText__dGFeW i {
  margin-right: 10px;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageStack/components/Warning/Warning.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;EACA,iFAAA;EAKA,4BAAA;EACA,cAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;AAHF;AAKE;EACE;IACE,4BAAA;EAHJ;EAME;IACE,0BAAA;EAJJ;AACF;AAOE;EACE,sDAAA;AALJ;AAQE;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;AANJ;AAQE;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,mBAAA;AANJ;AAOI;EACE,kBAAA;EACA,cAAA;AALN","sourcesContent":[".warningBox {\n  position: relative;\n  width: 380px;\n  max-width: 380px;\n  min-height: 50px;\n  height: max-content;\n  max-height: 200px;\n  overflow: auto;\n  background: linear-gradient(\n    135deg,\n    rgba(255, 207, 79) 0%,\n    rgba(255, 155, 74) 100%\n  );\n  padding: 15px 35px 15px 12px;\n  color: #ffffff;\n  border-radius: 15px;\n  display: flex;\n  align-items: flex-start;\n\n  @keyframes animateAlertIn {\n    0% {\n      transform: translateX(300px);\n    }\n\n    100% {\n      transform: translateX(0px);\n    }\n  }\n\n  &.isActive {\n    animation: animateAlertIn 0.25s ease-in;\n  }\n\n  .closeIcon {\n    position: absolute;\n    top: 13px;\n    right: 8px;\n    cursor: pointer;\n  }\n  .warningText {\n    font-size: 1rem;\n    font-weight: 600;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    padding-right: 20px;\n    i {\n      margin-right: 10px;\n      color: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningBox": `Warning_warningBox__NZKlP`,
	"isActive": `Warning_isActive__RPg5H`,
	"animateAlertIn": `Warning_animateAlertIn__SyRXN`,
	"closeIcon": `Warning_closeIcon__KW8ii`,
	"warningText": `Warning_warningText__dGFeW`
};
export default ___CSS_LOADER_EXPORT___;
