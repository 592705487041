// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  width: 300px;
  overflow: hidden;
  position: relative;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  width: 300px;
  box-sizing: border-box;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

.active {
  /* Style for the active item */
  border: 2px solid #333;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 24px;
  color: #333;
}

#prev {
  left: 10px;
}

#next {
  right: 10px;
}
.slick-dots {
  display: flex !important;
  justify-content: center;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Carousel/Carousel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,eAAe;EACf,eAAe;EACf,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;AACA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".carousel-container {\n  width: 300px;\n  overflow: hidden;\n  position: relative;\n}\n\n.carousel {\n  display: flex;\n  transition: transform 0.5s ease-in-out;\n}\n\n.carousel-item {\n  width: 300px;\n  box-sizing: border-box;\n}\n\n.carousel-item img {\n  width: 100%;\n  height: auto;\n}\n\n.active {\n  /* Style for the active item */\n  border: 2px solid #333;\n}\n\n.arrow {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  cursor: pointer;\n  font-size: 24px;\n  color: #333;\n}\n\n#prev {\n  left: 10px;\n}\n\n#next {\n  right: 10px;\n}\n.slick-dots {\n  display: flex !important;\n  justify-content: center;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
