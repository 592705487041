import React from "react";

const Button = (props) => {
  return (
    <button className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded">
      {props.data}
    </button>
  );
};

export default Button;
