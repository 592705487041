import React from 'react'

const Spinnerr = () => {
    return (
        <div className='max-w-16 m-auto animate-ping mt-12 h-16 rounded-full bg-sky-600'></div>
    )
}

export default Spinnerr


// import Spinner from 'react-bootstrap/Spinner';

// const  Spinnerr = () => {
//   return <Spinner animation="border" />;
// }

// export default Spinnerr;