import THEMES from "../themes";
import styles from "../Input.module.scss";

const InputSingle = ({
  type,
  value,
  setValue,
  name,
  top,
  left,
  width = "100%",
  height = "40px",
  className,
  showArrows = false,
  theme = "WHITE",
  maxValue = null,
  minValue = 0,
  disabled,
  isEmoji,
  ...rest
}) => {
  const onChange = (e) => {
    let value = e.target.value;
    setValue(value);
  };
  switch (type) {
    case "textarea":
      return (
        <textarea
          value={value}
          onChange={onChange}
          style={{ width, height }}
          name={name}
          className={`${styles.input} ${styles[THEMES[theme]]} ${
            className ?? ""
          }`}
          {...rest}
        />
      );

    default:
      return (
        <input
          value={value}
          onChange={onChange}
          name={name}
          style={{ width, height }}
          type={type}
          className={`${styles.input} ${styles[THEMES[theme]]}  ${
            className ?? ""
          }  `}
          disabled={disabled}
          {...rest}
        />
      );
  }
};

export default InputSingle;
