// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Label_label__gQPTE {
  display: block;
  color: #567191;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
  margin-bottom: 10px;
}
.Label_label__gQPTE::first-letter {
  text-transform: capitalize !important;
}
.Label_label__gQPTE.Label_required__zr6oE::after {
  content: "*";
  color: #f77272;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin/Label/Label.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AACF;AAAE;EACE,qCAAA;AAEJ;AAEI;EACE,YAAA;EACA,cAAA;AAAN","sourcesContent":[".label {\n  display: block;\n  color: #567191;\n  font-weight: 600;\n  font-size: 1rem;\n  line-height: 16px;\n  margin-bottom: 10px;\n  &::first-letter {\n    text-transform: capitalize !important;\n  }\n\n  &.required {\n    &::after {\n      content: \"*\";\n      color: #f77272;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `Label_label__gQPTE`,
	"required": `Label_required__zr6oE`
};
export default ___CSS_LOADER_EXPORT___;
