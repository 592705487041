import { useMutation } from "react-query";
import axios from "axios";

const useLogin = () => {
  const loginApi = (body) => {
    return axios.post(`/v1/admin/login`, body).then((res) => res?.data);
  };
  const { mutate: login, isLoading: loginLoading } = useMutation(loginApi);

  return {
    login,
    loginLoading,
  };
};

export default useLogin;
