// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoUploader_videoContainer__nS9Ei {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
  margin: 10px 0;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_uploadedVideos__Sd9KX {
  display: flex;
  align-self: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 2%;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_uploadedVideos__Sd9KX .VideoUploader_videoBox__8YLg4 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 32%;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_uploadedVideos__Sd9KX .VideoUploader_videoBox__8YLg4 i {
  position: absolute;
  cursor: pointer;
  color: #a282e8;
  right: -13px;
  top: 0px;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_videoUploader__i5NSr {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_videoUploader__i5NSr .VideoUploader_videoBox__8YLg4 {
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 10px;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_videoUploader__i5NSr .VideoUploader_videoBox__8YLg4 .VideoUploader_preview__iwLqH {
  display: flex;
  width: 50%;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_videoUploader__i5NSr .VideoUploader_videoBox__8YLg4 .VideoUploader_input__1s8mL {
  display: flex;
  justify-content: center;
  width: 50%;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_videoUploader__i5NSr .VideoUploader_videoBox__8YLg4 .VideoUploader_input__1s8mL .VideoUploader_dnd__DJLPi {
  display: flex;
  width: 100%;
  align-self: center;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_videoUploader__i5NSr .VideoUploader_uploadBtn__O6S64 {
  display: flex;
  align-self: center;
  width: 50%;
  justify-content: center;
}
.VideoUploader_videoContainer__nS9Ei .VideoUploader_videoUploader__i5NSr .VideoUploader_uploadBtn__O6S64 .VideoUploader_btn__SKfRa {
  height: 40px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Form/components/VideoUploader/VideoUploader.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;AACF;AACE;EACE,aAAA;EACA,kBAAA;EACA,WAAA;EACA,eAAA;EACA,OAAA;AACJ;AAAI;EACE,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,UAAA;AAEN;AAAM;EACE,kBAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;EACA,QAAA;AAER;AAGE;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AADJ;AAEI;EACE,aAAA;EACA,WAAA;EACA,SAAA;EACA,aAAA;AAAN;AAEM;EACE,aAAA;EACA,UAAA;AAAR;AAEM;EACE,aAAA;EACA,uBAAA;EACA,UAAA;AAAR;AACQ;EACE,aAAA;EACA,WAAA;EACA,kBAAA;AACV;AAGI;EACE,aAAA;EACA,kBAAA;EACA,UAAA;EACA,uBAAA;AADN;AAEM;EACE,uBAAA;AAAR","sourcesContent":[".videoContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-self: center;\n  width: 100%;\n  margin: 10px 0;\n\n  .uploadedVideos {\n    display: flex;\n    align-self: center;\n    width: 100%;\n    flex-wrap: wrap;\n    gap: 2%;\n    .videoBox {\n      display: flex;\n      position: relative;\n      flex-direction: column;\n      width: 32%;\n\n      i {\n        position: absolute;\n        cursor: pointer;\n        color: #a282e8;\n        right: -13px;\n        top: 0px;\n      }\n    }\n  }\n\n  .videoUploader {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    .videoBox {\n      display: flex;\n      width: 100%;\n      gap: 20px;\n      padding: 10px;\n\n      .preview {\n        display: flex;\n        width: 50%;\n      }\n      .input {\n        display: flex;\n        justify-content: center;\n        width: 50%;\n        .dnd {\n          display: flex;\n          width: 100%;\n          align-self: center;\n        }\n      }\n    }\n    .uploadBtn {\n      display: flex;\n      align-self: center;\n      width: 50%;\n      justify-content: center;\n      .btn {\n        height: 40px !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoContainer": `VideoUploader_videoContainer__nS9Ei`,
	"uploadedVideos": `VideoUploader_uploadedVideos__Sd9KX`,
	"videoBox": `VideoUploader_videoBox__8YLg4`,
	"videoUploader": `VideoUploader_videoUploader__i5NSr`,
	"preview": `VideoUploader_preview__iwLqH`,
	"input": `VideoUploader_input__1s8mL`,
	"dnd": `VideoUploader_dnd__DJLPi`,
	"uploadBtn": `VideoUploader_uploadBtn__O6S64`,
	"btn": `VideoUploader_btn__SKfRa`
};
export default ___CSS_LOADER_EXPORT___;
