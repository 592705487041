import React, { useEffect, useState } from "react";
import CommonComponents from "../../../components/common/CommonComponents";
import useAbout from "../../../data-access/useAbout";
const ChairmanDesk = () => {
  const { about, aboutLoading } = useAbout();
  const [chairman, setChairman] = useState();
  useEffect(() => {
    setChairman(about?.[0]);
  }, [about]);
  return (
    <div>
      <CommonComponents
        img={chairman?.chairmanImage?.[0] ?? ""}
        data={chairman?.chairmanDesc ?? ""}
        heading={chairman?.chairmanName ?? ""}
      />
    </div>
  );
};

export default ChairmanDesk;
