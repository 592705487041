// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  /* background: red; */
  width: 200px;
  position: absolute;
  /* top: 80px; */
  /* right: 0; */
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: #fff;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: rgb(157, 205, 157);
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #0d0000;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Dropdown/Dropdown.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,WAAW;EACX,qBAAqB;EACrB,cAAc;EACd,aAAa;AACf;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".dropdown-menu {\n  /* background: red; */\n  width: 200px;\n  position: absolute;\n  /* top: 80px; */\n  /* right: 0; */\n  list-style: none;\n  text-align: start;\n}\n\n.dropdown-menu li {\n  background: #fff;\n  cursor: pointer;\n}\n\n.dropdown-menu li:hover {\n  background: rgb(157, 205, 157);\n}\n\n.dropdown-menu.clicked {\n  display: none;\n}\n\n.dropdown-link {\n  display: block;\n  height: 100%;\n  width: 100%;\n  text-decoration: none;\n  color: #0d0000;\n  padding: 16px;\n}\n\n@media screen and (max-width: 960px) {\n  .fa-caret-down {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
