// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Member_memberPara__4-A2X {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Member_memberPara__4-A2X .Member_toggleBox__by0AU {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -25px;
  gap: 10px;
}
.Member_memberPara__4-A2X .Member_toggleBox__by0AU .Member_toggleHeading__mvdym {
  display: flex;
  color: #567191;
  font-weight: 600;
  font-size: 1rem;
  line-height: 16px;
  justify-content: center;
  align-self: center;
}
.Member_memberPara__4-A2X .Member_toggleBox__by0AU .Member_toggle__7mULl {
  display: flex;
  align-self: center;
}
.Member_memberPara__4-A2X .Member_toggleBox__by0AU .Member_delBtn__JGSNm {
  z-index: 3;
}`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Form/components/Member/Member.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;EACA,oBAAA;EACA,SAAA;AACJ;AACI;EACE,aAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;AACN;AACI;EACE,aAAA;EACA,kBAAA;AACN;AACI;EACE,UAAA;AACN","sourcesContent":[".memberPara {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n\n  .toggleBox {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: -25px;\n    gap: 10px;\n\n    .toggleHeading {\n      display: flex;\n      color: #567191;\n      font-weight: 600;\n      font-size: 1rem;\n      line-height: 16px;\n      justify-content: center;\n      align-self: center;\n    }\n    .toggle {\n      display: flex;\n      align-self: center;\n    }\n    .delBtn {\n      z-index: 3;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"memberPara": `Member_memberPara__4-A2X`,
	"toggleBox": `Member_toggleBox__by0AU`,
	"toggleHeading": `Member_toggleHeading__mvdym`,
	"toggle": `Member_toggle__7mULl`,
	"delBtn": `Member_delBtn__JGSNm`
};
export default ___CSS_LOADER_EXPORT___;
