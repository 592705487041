// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MessageStack_errorStack__3Q4yD {
  position: absolute;
  top: 10px;
  right: 10px;
  max-height: 60vh;
  overflow: auto;
  z-index: 2147483647;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  align-items: flex-end;
}
.MessageStack_errorStack__3Q4yD::-webkit-scrollbar {
  display: none;
  width: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageStack/MessageStack.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EAKA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,qBAAA;AAHF;AAJE;EACE,aAAA;EACA,QAAA;AAMJ","sourcesContent":[".errorStack {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  max-height: 60vh;\n  overflow: auto;\n  z-index: 2147483647;\n  &::-webkit-scrollbar {\n    display: none;\n    width: 0;\n  }\n  display: flex;\n  flex-direction: column;\n  gap: 0.7rem;\n  align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorStack": `MessageStack_errorStack__3Q4yD`
};
export default ___CSS_LOADER_EXPORT___;
