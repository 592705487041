import { useMutation } from "react-query";
import axios from "axios";

const useUpload = () => {
  const uploadApi = async (body) => {
    return await axios
      .post(`/v1/admin/upload`, body, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => res?.data?.data);
  };
  const { mutate: upload, isLoading: uploadLoading } = useMutation(uploadApi);

  return {
    upload,
    uploadLoading,
  };
};

export default useUpload;
