import { useMutation, useQuery } from "react-query";
import axios from "axios";

const useMember = ({ type }) => {
  const memberApi = async () => {
    return await axios.get(`v1/admin/member/${type}`).then((res) => {
      return res.data?.data;
    });
  };
  const {
    data: member,
    isLoading: memberLoading,
    refetch: refetchMember,
  } = useQuery(`member-${type}`, memberApi, {
    enabled: Boolean(type),
  });

  const createMemberApi = async (body) => {
    return await axios
      .post(`/v1/admin/member`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: createMember, isLoading: createMemberLoading } =
    useMutation(createMemberApi);

  const updateMemberApi = async ({ memberId, body }) => {
    return await axios
      .put(`/v1/admin/member/${memberId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: updateMember, isLoading: updateMemberLoading } =
    useMutation(updateMemberApi);

  const deleteMemberApi = async ({ memberId, body }) => {
    return await axios
      .delete(`/v1/admin/member/${memberId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: deleteMember, isLoading: deleteMemberLoading } =
    useMutation(deleteMemberApi);

  return {
    member,
    refetchMember,
    memberLoading,
    createMember,
    createMemberLoading,
    updateMember,
    updateMemberLoading,
    deleteMember,
    deleteMemberLoading,
  };
};
export default useMember;
