import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import axios from "axios";

const useAbout = () => {
  const aboutApi = async () => {
    return await axios.get(`v1/admin/about`).then((res) => {
      return res.data?.data;
    });
  };
  const { data: about, isLoading: aboutLoading } = useQuery("about", aboutApi);

  const createAboutApi = (body) => {
    return axios.post(`/v1/admin/about`, body).then((res) => res?.data?.data);
  };
  const { mutate: createAbout, isLoading: createAboutLoading } =
    useMutation(createAboutApi);

  return {
    about,
    aboutLoading,
    createAbout,
    createAboutLoading,
  };
};
export default useAbout;
