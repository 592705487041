import { useMutation, useQuery } from "react-query";
import axios from "axios";

const useWorkSection = ({ page }) => {
  const workSectionApi = async () => {
    return await axios.get(`v1/admin/workSection`).then((res) => {
      return res.data?.data;
    });
  };
  const {
    data: workSection,
    isLoading: workSectionLoading,
    refetch: refetchWorkSection,
  } = useQuery("workSection", workSectionApi, {
    enabled: page === "work_section",
  });

  const createWorkSectionApi = async (body) => {
    return await axios
      .post(`/v1/admin/workSection`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: createWorkSection, isLoading: createWorkSectionLoading } =
    useMutation(createWorkSectionApi);

  const updateWorkSectionApi = async ({ workSectionId, body }) => {
    return await axios
      .put(`/v1/admin/workSection/${workSectionId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: updateWorkSection, isLoading: updateWorkSectionLoading } =
    useMutation(updateWorkSectionApi);

  const deleteWorkSectionApi = async ({ workSectionId, body }) => {
    return await axios
      .delete(`/v1/admin/workSection/${workSectionId}`, body)
      .then((res) => res?.data?.data);
  };
  const { mutate: deleteWorkSection, isLoading: deleteWorkSectionLoading } =
    useMutation(deleteWorkSectionApi);

  return {
    workSection,
    refetchWorkSection,
    workSectionLoading,
    createWorkSection,
    createWorkSectionLoading,
    updateWorkSection,
    updateWorkSectionLoading,
    deleteWorkSection,
    deleteWorkSectionLoading,
  };
};
export default useWorkSection;
