import { ROLES } from "../constants/constants";
import { Form } from "../pages/Admin/Form/Form";
import Login from "../pages/Admin/Login/Login";
import ChairmanDesk from "../pages/User/AboutUs/ChairmanDesk";
import Executivemember from "../pages/User/AboutUs/Executivemember";
import ProfileOrganization from "../pages/User/AboutUs/ProfileOrganization";
import TrusteeMember from "../pages/User/AboutUs/TrusteeMember";
import VisionMission from "../pages/User/AboutUs/VisionMission";
import Registration from "../pages/User/LegalStatus/Registration";
import OurWork from "../pages/User/OurWork/OurWork";
import AnnualReports from "../pages/User/Reports/AnnualReports";
import AuditReports from "../pages/User/Reports/AuditReports";
import MediaCenter from "../pages/User/MediaCenter/MediaCenter";
import Project from "../pages/User/Project/Project";
import ContactUs from "../pages/User/Contactus/ContactUs";

const ADMIN_ROUTES = [
  {
    path: "/admin/form",
    component: <Form />,
  },
];

const USER_ROUTES = [];

export const ROLE_ROUTES = {
  [ROLES.ADMIN]: ADMIN_ROUTES,
  [ROLES.USER]: USER_ROUTES,
};

export const OPEN_ROUTES = [
  {
    path: "/chairmandesk",
    component: <ChairmanDesk />,
  },
  {
    path: "/executivemember",
    component: <Executivemember />,
  },
  {
    path: "/profileorganization",
    component: <ProfileOrganization />,
  },
  {
    path: "/trusteemember",
    component: <TrusteeMember />,
  },
  {
    path: "/visionmission",
    component: <VisionMission />,
  },
  {
    path: "/registration",
    component: <Registration />,
  },
  {
    path: "/annualreports",
    component: <AnnualReports />,
  },
  {
    path: "/auditreports",
    component: <AuditReports />,
  },
  {
    path: "/ourwork",
    component: <OurWork />,
  },
  {
    path: "/ourwork/project/:projectId",
    component: <Project />,
  },
  {
    path: "/mediacenter",
    component: <MediaCenter />,
  },
  {
    path: "/contactus",
    component: <ContactUs />,
  },
];
