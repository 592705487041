import React, { useEffect } from "react";
import CommonComponents from "../../../components/common/CommonComponents";
import useMember from "../../../data-access/useMember";
const TrusteeMember = () => {
  const { member, memberLoading } = useMember({ type: "trustee" });

  return (
    <div className="flex flex-col gap-4">
      {member?.map((mem, i) => (
        <CommonComponents
          order={i % 2 !== 0}
          heading={mem?.name}
          data={mem?.description}
          img={mem?.image?.[0]}
        />
      ))}
    </div>
  );
};

export default TrusteeMember;
