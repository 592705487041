// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --ck-z-default: 10000000 !important;
}
.ck.ck-button {
  cursor: pointer;
}
li {
  display: list-item !important;
}
.ck-editor__editable_inline {
  padding: 0 30px !important;
  min-height: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Admin/Form/components/Editor/Editor.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;AACrC;AACA;EACE,eAAe;AACjB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,0BAA0B;EAC1B,iBAAiB;AACnB","sourcesContent":[":root {\n  --ck-z-default: 10000000 !important;\n}\n.ck.ck-button {\n  cursor: pointer;\n}\nli {\n  display: list-item !important;\n}\n.ck-editor__editable_inline {\n  padding: 0 30px !important;\n  min-height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
