import React, { useEffect, useState } from "react";
import ProjectCommonComponents from "./components/CommonComponents";
import useProject from "../../../data-access/useProject";
import { useParams } from "react-router-dom";

const Project = () => {
  let { projectId } = useParams();
  const { singleProject, singleProjectLoading } = useProject({ projectId });
  const [project, setProject] = useState();

  useEffect(() => {
    setProject(singleProject?.[0]);
  }, [singleProject]);

  return (
    <div>
      <ProjectCommonComponents
        img={project?.image?.[0] ?? ""}
        data={project?.description ?? ""}
        heading={project?.name ?? ""}
      />
    </div>
  );
};

export default Project;
