import React, { useContext, useEffect, useState } from "react";
import styles from "./Projects.module.scss";
import ImageUploader from "../ImageUploader/ImageUploader";
import Label from "../../../../../components/Admin/Label/Label";
import Input from "../../../../../components/Admin/Input/Input";
import Editor from "../Editor/Editor";
import ThemedButton from "../../../../../components/Admin/ThemedButton/ThemedButton";
import Toggle from "../../../../../components/Admin/Toggle/Toggle";
import useProject from "../../../../../data-access/useProject";
import { MessageContext } from "../../../../../context/message/MessageContext";

const Projects = ({ element, setElement, project }) => {
  const [input, setInput] = useState({ name: "", description: "", image: [] });
  const [newProject, setNewProject] = useState(!element);
  const { addError, addSuccess } = useContext(MessageContext);

  const {
    refetchProject,
    createProject,
    createProjectLoading,
    updateProject,
    updateProjectLoading,
    deleteProject,
    deleteProjectLoading,
  } = useProject({ page: "" });

  const handleSave = () => {
    if (!input?.name || !input?.description || !input?.image?.length) {
      return addError({ text: "Fill all the details of the project." });
    }

    if (newProject)
      createProject(input, {
        onSuccess: (data) => {
          refetchProject();
          return addSuccess("New Project added successfully.");
        },
        onError: (err) => {
          addError({
            text: `Error in adding project: ${err.response.data.msg}`,
          });
        },
      });
    else {
      if (!element)
        return addError({
          text: "Select a project to update or create new project.",
        });
      updateProject(
        { projectId: element, body: input },
        {
          onSuccess: (data) => {
            refetchProject();
            return addSuccess("Project updated successfully.");
          },
          onError: (err) => {
            addError({
              text: `Error in updating project: ${err.response.data.msg}`,
            });
          },
        }
      );
    }
  };

  const handleDelete = () => {
    deleteProject(
      { projectId: element },
      {
        onSuccess: (data) => {
          refetchProject();
          return addSuccess("Project deleted successfully.");
        },
        onError: (err) => {
          addError({
            text: `Error in deleting project: ${err.response.data.msg}`,
          });
        },
      }
    );
  };

  useEffect(() => {
    if (element) {
      const pro = project?.find((pro) => pro?._id === element);
      setInput({
        name: pro?.name,
        description: pro?.description,
        image: pro?.image,
      });
    } else setInput({ name: "", description: "", image: [] });

    setNewProject(!element);
  }, [element]);

  useEffect(() => {
    if (newProject) {
      setInput({ name: "", description: "", image: [] });
      setElement("");
    }
  }, [newProject]);

  return (
    <div className={styles.projectPara}>
      <div className={styles.toggleBox}>
        {" "}
        <div className={styles.toggleHeading}>Create new project</div>
        <Toggle
          checked={newProject}
          theme={"PURPLE"}
          onChange={() => setNewProject((prev) => !prev)}
          className={styles.toggle}
        />
        {element && (
          <ThemedButton
            theme={"RED"}
            onClick={() => handleDelete()}
            width={"100px"}
            height={"30px"}
            loading={deleteProjectLoading}
            className={styles.delBtn}
          >
            Delete
          </ThemedButton>
        )}
      </div>

      <div className={styles.nameBox}>
        <Label>Name</Label>
        <Input value={input} setValue={setInput} name={"name"} />
      </div>
      <div className={styles.imageBox}>
        <Label>Upload Project Image</Label>
        <ImageUploader input={input} setInput={setInput} name={"image"} />
      </div>
      <div className={styles.descBox}>
        <Label>Description</Label>
        <Editor input={input} setInput={setInput} name={"description"} />
      </div>
      <ThemedButton
        theme={"PRIMARY"}
        onClick={() => handleSave()}
        width={"100%"}
        height={"40px"}
        className={styles.btn}
        loading={createProjectLoading || updateProjectLoading}
      >
        {newProject ? "Create Project" : "Update Project"}
      </ThemedButton>
    </div>
  );
};

export default Projects;
