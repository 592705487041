import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Form.module.scss";
import Label from "../../../components/Admin/Label/Label";
import Select from "../../../components/Admin/Select/Select";
import { ELEMENT_OPTIONS, PAGES, PAGES_OPTIONS } from "./constants";
import About from "./components/About/About";
import Report from "./components/Report/Report";
import Media from "./components/Media/Media";
import useProject from "../../../data-access/useProject";
import { MessageContext } from "../../../context/message/MessageContext";
import Projects from "./components/Projects/Projects";
import useWorkSection from "../../../data-access/useWorkSection";
import WorkSections from "./components/WorkSections/WorkSections";
import Members from "./components/Member/Member";
import ThemedButton from "../../../components/Admin/ThemedButton/ThemedButton";
import adminContext from "../../../context/admin/adminContext";
import { redirect, useNavigate } from "react-router-dom";

export const Form = () => {
  const [page, setPage] = useState("");
  const [element, setElement] = useState("");
  const [options, setOptions] = useState([]);

  const navigate = useNavigate();
  const { project, projectLoading } = useProject({ page });
  const { workSection, workSectionLoading } = useWorkSection({ page });

  const { addWarning, addSuccess } = useContext(MessageContext);
  const { dispatch } = useContext(adminContext);

  // const [input, setInput] = useState({
  //   homeImages: [
  //     "https://cdn.pixabay.com/photo/2015/07/05/10/18/tree-832079_640.jpg",
  //     "https://img.freepik.com/free-photo/beautiful-mountains-landscape_23-2151151076.jpg?size=626&ext=jpg",
  //     "https://cdn.pixabay.com/photo/2015/07/05/10/18/tree-832079_640.jpg",
  //     "https://img.freepik.com/free-photo/beautiful-mountains-landscape_23-2151151076.jpg?size=626&ext=jpg",
  //   ],
  //   homeVideos: [
  //     "https://youtu.be/QDIOBsMBEI0?si=Ge0kljQflDlHhfNJ",
  //     "https://res.cloudinary.com/dmefjttzo/video/upload/v1711560459/A._Insurance_Shivansh_cfw83d.mp4",
  //     "https://youtu.be/QDIOBsMBEI0?si=Ge0kljQflDlHhfNJ",
  //   ],
  //   homePdfs: [
  //     "https://res.cloudinary.com/dmefjttzo/image/upload/v1711708006/1711708005486-cadences.pdf.pdf",
  //   ],
  //   edit: "<p>Vansh</p>",
  // });

  useEffect(() => {
    if (page === PAGES.PROJECTS) {
      if (project) {
        if (project?.length === 0) {
          setOptions([]);
          addWarning("There are no projects uploaded yet. Create new project.");
        } else {
          setOptions(project.map((p) => ({ label: p.name, value: p._id })));
        }
      }
    } else if (page === PAGES.WORK_SECTION) {
      if (workSection) {
        if (workSection?.length === 0) {
          setOptions([]);
          addWarning(
            "There are no workSections uploaded yet. Create new workSection."
          );
        } else {
          setOptions(workSection.map((p) => ({ label: p.name, value: p._id })));
        }
      }
    }
    setElement("");
  }, [page, project, workSection]);

  const handleLogout = () => {
    dispatch({ type: "logout" });
    addSuccess("You have logged out successfully.");
    navigate("/admin/login");
  };

  const renderForm = () => {
    switch (page) {
      case "about":
        return <About element={element} />;
      case "report":
        return <Report element={element} />;
      case "media":
        return <Media element={element} />;
      case "projects":
        return (
          <Projects
            element={element}
            setElement={setElement}
            project={project}
          />
        );
      case "work_section":
        return (
          <WorkSections
            element={element}
            setElement={setElement}
            workSection={workSection}
          />
        );
      case "member":
        return <Members element={element} />;

      default:
        break;
    }
  };
  return (
    <div className={styles.formBox}>
      <ThemedButton
        theme={"PRIMARY"}
        onClick={() => handleLogout()}
        width={"150px"}
        height={"40px"}
        className={styles.btn}
      >
        Logout
      </ThemedButton>
      <div className={styles.form}>
        <div className={styles.heading}>Admin Portal</div>
        <div className={styles.inputs}>
          <div className={styles.pages}>
            <Label>Pages</Label>
            <Select
              placeholder="Select here"
              value={page}
              height={"30px"}
              setValue={setPage}
              options={PAGES_OPTIONS}
              numberOfOptionsVisible="3"
            ></Select>
          </div>
          <div className={styles.elements}>
            <Label>Elements</Label>
            <Select
              placeholder="Select here"
              value={element}
              setValue={setElement}
              options={ELEMENT_OPTIONS[page] ?? options}
              loading={projectLoading || workSectionLoading}
              height={"30px"}
              numberOfOptionsVisible="3"
            ></Select>
          </div>
        </div>
        <div className={styles.formInside}>{renderForm()}</div>
      </div>
    </div>
  );
};
