import React from "react";
import ProjectCarousel from "./ProjectCarousel";
import useProject from "../../data-access/useProject";

const OurProjects = () => {
  const { project, projectLoading } = useProject({ page: "projects" });
  return (
    <div className="max-w-[90%] m-auto">
      <ProjectCarousel
        projects={project?.map((pro) => ({
          projectName: pro?.name,
          description: pro?.description,
          img: pro?.image?.[0],
        }))}
      />
    </div>
  );
};

export default OurProjects;
