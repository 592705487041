import React from "react";
import useWorkSection from "../../../data-access/useWorkSection";
import Carousel from "../../../components/Carousel/projectCarousel";

const OurWork = () => {
  const { workSection, workSectionLoading } = useWorkSection({
    page: "work_section",
  });

  return (
    <div className="container flex flex-col gap-4 mx-auto">
      {workSection?.map((ws) => (
        <div className="section1 flex flex-col">
          <h2
            className={`flex flex-col m-6 max-w-100 text-2xl font-semibold self-center justify-center ${
              ws?.link
                ? "hover:font-bold  hover:text-green-400 cursor-pointer"
                : ""
            }`}
            onClick={() => ws?.link && window.open(ws?.link, "_blank")}
          >
            {ws?.name}{" "}
          </h2>
          <Carousel
            data={ws?.projects?.map((m) => ({
              name: m?.name,
              about: m?.description,
              urlImg: m?.image?.[0],
              projectId: m?._id,
            }))}
            path={""}
          />
        </div>
      ))}
    </div>
  );
};

export default OurWork;
