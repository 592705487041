const LegalStatus = [
  {
    title: "Registration",
    path: "/registration",
    cName: "dropdown-link",
  },
];

const OurWorkItems = [
  {
    title: "Entrepreneurship",
    path: "/marketing",
    cName: "dropdown-link",
  },
  {
    title: "Seminar",
    path: "/consulting",
    cName: "dropdown-link",
  },
  {
    title: "Economic Survey",
    path: "/design",
    cName: "dropdown-link",
  },
  {
    title: "Climate Change",
    path: "/development",
    cName: "dropdown-link",
  },
  {
    title: "Educational Program",
    path: "/development",
    cName: "dropdown-link",
  },
];
const AboutUsItem = [
  {
    title: "Chairman Desk",
    path: "/chairmandesk",
    cName: "dropdown-link",
  },
  {
    title: "Trustee Member",
    path: "/trusteemember",
    cName: "dropdown-link",
  },
  {
    title: "Executive Member",
    path: "/executivemember",
    cName: "dropdown-link",
  },
  {
    title: "Vision Mission",
    path: "/visionmission",
    cName: "dropdown-link",
  },
  {
    title: "Profile Organization",
    path: "/profileorganization",
    cName: "dropdown-link",
  },
];
const OurWork = [
  {
    title: "Chairman Desk",
    path: "/vi1",
    cName: "dropdown-link",
  },
  {
    title: "Executive Member",
    path: "/vi2",
    cName: "dropdown-link",
  },
  {
    title: "Profile Organization",
    path: "/vi3",
    cName: "dropdown-link",
  },
  {
    title: "Trustee Member",
    path: "/vii1",
    cName: "dropdown-link",
  },
  {
    title: "Vision Mission",
    path: "/vii2",
    cName: "dropdown-link",
  },
];

const ReportsItem = [
  {
    title: "Annual Reports",
    path: "/annualreports",
    cName: "dropdown-link",
  },
  {
    title: "Audit Reports",
    path: "/auditreports",
    cName: "dropdown-link",
  },
];

const ContactUsItems = [
  {
    title: "Corporate Offices",
    path: "/marketing",
    cName: "dropdown-link",
  },
  {
    title: "Enquiry Office",
    path: "/consulting",
    cName: "dropdown-link",
  },
];

module.exports = {
  LegalStatus,
  OurWorkItems,
  ReportsItem,
  ContactUsItems,
  AboutUsItem,
  OurWork,
};
